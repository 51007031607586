.CreditCardForm {
  display: flex;
  position: relative;
  flex-direction: row; }
  @media screen and (max-width: 500px) {
    .CreditCardForm {
      flex-direction: column; } }
  .CreditCardForm .pricing {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 8px 0;
    color: #013243; }
    .CreditCardForm .pricing h4 {
      font-size: 1.2em;
      color: #013243; }
    .CreditCardForm .pricing h2 {
      color: #013243;
      font-weight: bold;
      font-size: 1.3em; }
    .CreditCardForm .pricing p {
      font-size: 0.8em;
      text-align: justify;
      padding: 0 8px;
      line-height: 14px;
      color: #013243; }
  .CreditCardForm .fields {
    flex: 3;
    padding-right: 32px; }
    @media screen and (max-width: 500px) {
      .CreditCardForm .fields {
        padding-right: 16px; } }
    .CreditCardForm .fields .ms-Grid-col {
      margin-top: 16px; }
    .CreditCardForm .fields .stripe-element {
      color: #8a8886 !important;
      box-sizing: border-box;
      box-shadow: none;
      margin: 0;
      display: block;
      word-wrap: break-word;
      overflow-wrap: break-word;
      line-height: 19px;
      font-weight: 600; }
      .CreditCardForm .fields .stripe-element .StripeElement {
        background-color: #fff;
        color: #323130 !important;
        height: 32px;
        border: 1px solid #8a8886;
        padding: 8px;
        box-sizing: border-box;
        border-radius: 2px; }
        .CreditCardForm .fields .stripe-element .StripeElement:focus, .CreditCardForm .fields .stripe-element .StripeElement:active {
          border-width: 2px !important;
          border-color: var(--primary-color) !important; }
        .CreditCardForm .fields .stripe-element .StripeElement:hover {
          border-color: #8a8886; }
      .CreditCardForm .fields .stripe-element:focus, .CreditCardForm .fields .stripe-element:active {
        border-width: 2px !important;
        border-color: var(--primary-color) !important; }
