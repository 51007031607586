.AddReportDialog {
  width: 55%;
  min-width: 55% !important;
  max-width: 55% !important;
  overflow: hidden !important; }
  .AddReportDialog > div:first-of-type {
    width: 100%; }
  .AddReportDialog .ms-Dialog-inner {
    overflow: hidden !important;
    width: auto; }
    .AddReportDialog .ms-Dialog-inner .add-disclosure-report {
      padding: 0 24px 64px;
      position: relative;
      overflow: auto;
      width: auto; }
  .AddReportDialog .campaign-maintainer {
    padding: 8px;
    background-color: #f3f3f3; }
  .AddReportDialog .add-report-actions {
    padding-top: 16px;
    border-top: 1px solid #999;
    height: auto;
    width: 100%;
    text-align: right; }
  @media screen and (max-width: 800px) {
    .AddReportDialog {
      width: 100%;
      min-width: 100% !important;
      max-width: 100% !important;
      height: 79vh; } }
  @media screen and (min-width: 801px) and (max-width: 1160px) {
    .AddReportDialog {
      width: 85%;
      min-width: 85% !important;
      max-width: 85% !important; } }
