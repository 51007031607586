.Summary .lead-column {
  display: flex;
  margin-top: 64px;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  @media screen and (max-width: 639px) {
    .Summary .lead-column {
      margin-top: 0; } }

.Summary .summary-label {
  font-weight: bold;
  font-size: 1.3rem; }
  .Summary .summary-label + p {
    padding: 0;
    font-size: 1.1rem;
    margin-bottom: 24px;
    margin-top: 0; }
