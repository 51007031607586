.BeginningBalanceSourceDialog {
  width: 700px;
  min-width: 700px !important;
  max-width: 700px !important;
  overflow: hidden !important; }
  .BeginningBalanceSourceDialog > div:first-of-type {
    width: 100%; }
  .BeginningBalanceSourceDialog .ms-Dialog-inner {
    overflow: hidden !important;
    width: auto; }
  .BeginningBalanceSourceDialog .footer {
    padding-top: 16px;
    border-top: 1px solid #999;
    height: auto;
    width: 100%;
    text-align: right; }
