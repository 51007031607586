.AddEditDeposit {
  width: 100%;
  height: auto;
  margin-top: 40px;
  display: flex;
  align-items: stretch; }
  .AddEditDeposit .section-loading {
    height: calc(100vh - 164px);
    display: flex;
    align-items: center;
    justify-content: center; }
  .AddEditDeposit .options-panel {
    width: 300px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #dadada;
    position: absolute;
    height: calc(100vh - 164px);
    overflow-y: auto; }
    .AddEditDeposit .options-panel .deposit-field {
      margin-bottom: 16px; }
    .AddEditDeposit .options-panel .deposit-actions {
      text-align: center;
      margin-top: 32px; }
  .AddEditDeposit .deposits-list {
    left: 300px;
    right: 0;
    box-sizing: border-box;
    background-color: #fff;
    height: calc(100vh - 164px);
    position: absolute; }
    .AddEditDeposit .deposits-list .search-actions {
      display: flex;
      box-sizing: border-box;
      justify-content: flex-start;
      align-items: center;
      padding-left: 16px; }
      .AddEditDeposit .deposits-list .search-actions .search-box {
        margin-right: 32px; }
      .AddEditDeposit .deposits-list .search-actions .cc-filter {
        margin: 26px 0 0 12px; }
    .AddEditDeposit .deposits-list .select-all-checkbox {
      position: absolute;
      z-index: 99;
      left: 11px;
      top: 116px; }
  @media screen and (max-width: 799px) {
    .AddEditDeposit .options-panel {
      width: 250px; }
    .AddEditDeposit .deposits-list {
      left: 250px;
      flex-direction: column;
      align-items: flex-start; } }
  @media screen and (min-width: 640px) and (max-width: 656px) {
    .AddEditDeposit .deposits-list .select-all-checkbox {
      top: 144px; } }
  @media screen and (max-width: 639px) {
    .AddEditDeposit .deposits-list {
      left: 0; }
      .AddEditDeposit .deposits-list .select-all-checkbox {
        top: 192px; }
      .AddEditDeposit .deposits-list .search-actions-sm {
        box-sizing: border-box;
        padding: 4px 8px; }
        .AddEditDeposit .deposits-list .search-actions-sm .cc-filter {
          margin-top: 26px; } }
  @media screen and (max-width: 479px) {
    .AddEditDeposit .deposits-list {
      left: 0; }
      .AddEditDeposit .deposits-list .select-all-checkbox {
        top: 244px; }
      .AddEditDeposit .deposits-list .search-actions-sm {
        box-sizing: border-box;
        padding: 4px 8px; }
        .AddEditDeposit .deposits-list .search-actions-sm .cc-filter {
          margin-top: 26px; } }
