.VerifyReport {
  margin: 10px auto 64px;
  width: 83%; }
  .VerifyReport .error {
    color: #a00000; }
  .VerifyReport .instructions {
    background-color: #fff;
    padding: 24px;
    margin-bottom: 16px; }
    .VerifyReport .instructions p {
      width: 95%;
      margin: 8px auto 24px;
      line-height: 27px; }
  .VerifyReport .verify-report-content {
    display: flex;
    align-items: stretch;
    box-sizing: border-box;
    flex-wrap: wrap; }
    .VerifyReport .verify-report-content .report {
      flex: 1;
      background-color: #fff;
      padding: 16px;
      margin-right: 16px;
      min-height: 300px;
      box-sizing: border-box;
      max-width: calc(60% - 32px); }
      .VerifyReport .verify-report-content .report pre {
        background-color: #e3ecf7;
        border: 1px solid #cdd7e2;
        color: #607188;
        padding: 10px;
        overflow: auto;
        max-height: 150px; }
      .VerifyReport .verify-report-content .report .verify-report-actions {
        margin-top: 16px; }
    .VerifyReport .verify-report-content .response {
      flex: 2;
      background-color: #fff;
      padding: 16px;
      box-sizing: border-box; }
      .VerifyReport .verify-report-content .response .error-field-wrapper {
        padding: 4px 8px;
        background-color: #f3f3f3;
        margin-bottom: 8px;
        border: 1px solid rgba(55, 55, 55, 0.2); }
    @media screen and (max-width: 860px) {
      .VerifyReport .verify-report-content {
        flex-direction: column; }
        .VerifyReport .verify-report-content .report {
          flex: 1;
          margin-right: 0;
          margin-bottom: 16px;
          max-width: 100%; }
        .VerifyReport .verify-report-content .response {
          flex: 1;
          max-width: 100%; } }
