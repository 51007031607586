.PaymentStep {
  display: flex;
  position: relative;
  flex-direction: column; }
  @media screen and (max-width: 500px) {
    .PaymentStep {
      flex-direction: column; } }
  .PaymentStep .actions {
    margin-top: 32px; }
    .PaymentStep .actions .back-btn {
      margin-right: 8px; }
