.Dashboard {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100vw; }
  .Dashboard .header {
    flex: 65px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 4px 24px;
    display: flex;
    align-items: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    z-index: 25; }
    @media screen and (max-width: 479px) {
      .Dashboard .header {
        padding: 4px 8px; } }
    @media print {
      .Dashboard .header {
        display: none; } }
    .Dashboard .header .logo {
      width: 95px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .Dashboard .header .user-menu {
      flex: 2;
      text-align: right;
      display: flex;
      justify-content: flex-end; }
      .Dashboard .header .user-menu .search {
        width: 280px;
        margin-right: 16px; }
    .Dashboard .header .admin-campaign-select {
      display: flex;
      align-items: center; }
  .Dashboard .selected-campaign {
    padding: 4px 8px 4px 32px;
    background-color: #fafafa;
    border-top: 1px solid #919191aa;
    z-index: 25;
    margin-bottom: 0; }
    @media print {
      .Dashboard .selected-campaign {
        display: none; } }
  .Dashboard .content {
    margin: 0;
    padding: 0;
    height: calc(100vh - 125px);
    overflow: auto; }
    @media print {
      .Dashboard .content {
        height: auto; } }
    .Dashboard .content .choose-a-campaign {
      position: fixed;
      top: 60px;
      left: 0;
      right: 0;
      bottom: 60px;
      padding: 35px 65px 35px 135px;
      background-color: rgba(255, 255, 255, 0.9);
      z-index: 99;
      display: flex; }
