@keyframes slideinfromtop {
  from {
    transform-origin: 0 0;
    transform: translate3d(0, -100px, 0); }
  to {
    transform: translate3d(0, 4px, 0); } }

.Alert {
  position: fixed;
  display: flex;
  align-items: center;
  height: auto;
  top: 4px;
  left: 4px;
  right: 4px;
  z-index: 99;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  color: #fff;
  padding: 8px 24px;
  box-sizing: border-box;
  animation: slideinfromtop 500ms var(--animation-curve) 0ms; }
  .Alert.INFO {
    background-color: var(--info-color); }
  .Alert.SUCCESS {
    background-color: var(--success-color); }
  .Alert.ERROR {
    background-color: var(--error-color); }
  .Alert.WARNING {
    background-color: var(--warning-color); }
  .Alert p {
    margin-left: 16px;
    flex-grow: 1;
    margin-right: 16px; }
