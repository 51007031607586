.ForgotPassword {
  background-color: #fff;
  margin: 60px auto 0;
  padding: 24px;
  max-width: 600px;
  box-sizing: border-box; }
  @media screen and (max-width: 639px) {
    .ForgotPassword {
      width: 98vw;
      position: absolute;
      left: 1vw;
      top: 1vh;
      height: 94vh;
      margin: 0 auto;
      max-width: none; } }
  .ForgotPassword .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 32px; }
  .ForgotPassword .actions {
    margin-top: 32px; }
  .ForgotPassword .cancel-btn {
    margin-right: 16px; }
  .ForgotPassword .ms-TextField-wrapper .ms-TextField-fieldGroup {
    background: #fff; }
