.ResetPassword {
  background-color: #fff;
  margin: 60px auto 0;
  padding: 24px;
  max-width: 600px;
  box-sizing: border-box; }
  .ResetPassword .ms-Grid-col {
    margin-bottom: 8px; }
  .ResetPassword .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 32px; }
  .ResetPassword .actions {
    margin-top: 32px; }
  .ResetPassword .cancel-btn {
    margin-right: 16px; }
