.ListsIndex {
  width: 90%;
  background-color: #fff;
  padding: 24px;
  margin: 48px auto 0;
  box-sizing: border-box;
  min-height: 600px; }
  .ListsIndex .list-options {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch; }
    .ListsIndex .list-options .list-option {
      min-height: 150px;
      min-width: 275px;
      margin: 16px 16px 0;
      border: 1px solid rgba(0, 0, 0, 0.12);
      padding: 16px 16px 0; }
      .ListsIndex .list-options .list-option .list-option-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        margin: 0;
        padding: 0 0 24px 0;
        box-sizing: border-box; }
        .ListsIndex .list-options .list-option .list-option-header .help-icon {
          height: 40px; }
        .ListsIndex .list-options .list-option .list-option-header h4 {
          margin: 0 16px 0 0;
          padding: 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
        .ListsIndex .list-options .list-option .list-option-header > i {
          display: flex;
          font-size: 16px;
          color: white;
          background-color: #919191;
          padding: 8px;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          height: 24px;
          width: 24px; }
      .ListsIndex .list-options .list-option .list-option-contents {
        display: flex;
        align-items: stretch;
        flex-direction: column;
        justify-content: space-between; }
        .ListsIndex .list-options .list-option .list-option-contents > button {
          margin-bottom: 16px; }
