.EditInitialIndebtednessDetails {
  background-color: #fff;
  width: 100%;
  height: auto;
  position: relative;
  padding: 24px;
  margin-top: 24px;
  box-sizing: border-box; }
  .EditInitialIndebtednessDetails h3 {
    margin: 0 0 16px 0;
    padding: 0 0 8px 0;
    border-bottom: 1px solid #999; }
  .EditInitialIndebtednessDetails .edit-indebtedness-details-content {
    height: 0;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 100%; }
    .EditInitialIndebtednessDetails .edit-indebtedness-details-content .ms-Grid-col {
      margin-bottom: 16px; }
    .EditInitialIndebtednessDetails .edit-indebtedness-details-content.expanded {
      height: auto;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }
    .EditInitialIndebtednessDetails .edit-indebtedness-details-content .cancel-btn {
      margin-right: 16px; }
    .EditInitialIndebtednessDetails .edit-indebtedness-details-content .add-debt {
      border-right: 1px solid #999;
      padding: 16px 32px; }
      .EditInitialIndebtednessDetails .edit-indebtedness-details-content .add-debt .debt-actions {
        padding: 32px 8px; }
      @media screen and (max-width: 639px) {
        .EditInitialIndebtednessDetails .edit-indebtedness-details-content .add-debt {
          border: none;
          padding: 8px 16px; }
          .EditInitialIndebtednessDetails .edit-indebtedness-details-content .add-debt .debt-actions {
            padding: 16px 8px; } }
    .EditInitialIndebtednessDetails .edit-indebtedness-details-content .current-debts {
      padding: 16px 32px;
      display: flex;
      flex-direction: column;
      align-items: stretch; }
      .EditInitialIndebtednessDetails .edit-indebtedness-details-content .current-debts .debt-item {
        padding: 8px 16px;
        display: flex;
        align-items: center;
        border: 1px solid #ddd;
        border-bottom: 1px solid #999;
        background-color: #f4f4f4;
        margin: 0 auto; }
        .EditInitialIndebtednessDetails .edit-indebtedness-details-content .current-debts .debt-item h4 {
          font-weight: 600;
          font-size: 1em;
          margin: 0;
          padding: 0; }
        .EditInitialIndebtednessDetails .edit-indebtedness-details-content .current-debts .debt-item p {
          margin: 0; }
        .EditInitialIndebtednessDetails .edit-indebtedness-details-content .current-debts .debt-item > div {
          display: flex;
          align-items: center; }
          .EditInitialIndebtednessDetails .edit-indebtedness-details-content .current-debts .debt-item > div:first-of-type {
            flex: 2;
            flex-direction: column;
            padding-right: 16px; }
          .EditInitialIndebtednessDetails .edit-indebtedness-details-content .current-debts .debt-item > div:last-of-type {
            flex: 1;
            justify-content: flex-end; }
      @media screen and (max-width: 639px) {
        .EditInitialIndebtednessDetails .edit-indebtedness-details-content .current-debts {
          padding: 8px; }
          .EditInitialIndebtednessDetails .edit-indebtedness-details-content .current-debts .debt-item {
            padding: 8px; }
            .EditInitialIndebtednessDetails .edit-indebtedness-details-content .current-debts .debt-item > div:first-of-type {
              padding-right: 0; } }
