.ReconciliationReport {
  background-color: white;
  margin: 30px auto;
  padding: 32px 48px 128px;
  width: 75%;
  box-sizing: border-box; }
  .ReconciliationReport .print-link {
    cursor: pointer;
    text-align: right;
    display: block;
    color: blue;
    text-decoration: underline; }
  @media print {
    .ReconciliationReport {
      box-shadow: none !important;
      padding: 8px;
      margin: 0cm !important; }
      .ReconciliationReport .print-link {
        display: none; } }
  @media print and (orientation: landscape) {
    .ReconciliationReport {
      width: 10.75in; } }
  @media print and (orientation: portrait) {
    .ReconciliationReport {
      width: 8.25in; } }
  .ReconciliationReport .heading {
    width: 100%;
    text-align: center; }
    .ReconciliationReport .heading p {
      margin: 0;
      padding: 0;
      line-height: 24px;
      font-weight: bold; }
  .ReconciliationReport .content {
    height: auto;
    overflow: visible;
    padding: 0 24px;
    box-shadow: none; }
    @media print {
      .ReconciliationReport .content {
        padding: 0; } }
    .ReconciliationReport .content .page-break {
      display: none; }
    .ReconciliationReport .content h4 {
      width: 100%;
      text-decoration: underline; }
    .ReconciliationReport .content table {
      width: 100%;
      border-collapse: collapse;
      height: auto;
      max-height: 100vh;
      page-break-after: avoid;
      page-break-before: avoid; }
      .ReconciliationReport .content table thead {
        page-break-after: avoid;
        page-break-before: avoid; }
        .ReconciliationReport .content table thead tr {
          page-break-after: avoid;
          page-break-before: avoid; }
          .ReconciliationReport .content table thead tr th {
            border-bottom: 1px solid #919191;
            text-align: left;
            padding: 4px 4px 4px 8px; }
            .ReconciliationReport .content table thead tr th.amount {
              text-align: right; }
      .ReconciliationReport .content table tbody tr td {
        padding: 4px 4px 4px 8px;
        text-align: left; }
        .ReconciliationReport .content table tbody tr td.amount {
          text-align: right; }
      .ReconciliationReport .content table tfoot {
        border-top: 1px solid #919191; }
        .ReconciliationReport .content table tfoot tr {
          height: 50px; }
          .ReconciliationReport .content table tfoot tr td {
            padding: 4px 4px 4px 8px; }
            .ReconciliationReport .content table tfoot tr td.amount {
              text-align: right; }
              .ReconciliationReport .content table tfoot tr td.amount span {
                margin-right: 24px; }

@media print {
  html,
  body {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: auto; } }
