.App {
  height: auto;
  width: auto;
  box-sizing: border-box;
  margin: 0;
  padding: 0; }
  .App h3 {
    font-weight: 100;
    color: #2c3e50;
    text-transform: uppercase; }
    .App h3 strong {
      font-weight: bold; }
  .App .page-content {
    position: absolute;
    top: 60px;
    bottom: 50px;
    left: 0;
    right: 0;
    overflow: auto;
    padding-bottom: 40px; }
  .App [id*='amount'].ms-DetailsHeader-cellTitle,
  .App [id*='credit'].ms-DetailsHeader-cellTitle,
  .App [id*='debit'].ms-DetailsHeader-cellTitle,
  .App [id*='balance'].ms-DetailsHeader-cellTitle {
    justify-content: flex-end; }
  .App .footer {
    min-width: 375px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background-color: #2b2f35;
    color: #fff;
    padding: 4px 24px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .App .footer .info {
      font-size: 0.8em; }
      .App .footer .info div {
        text-align: left; }
    @media print {
      .App .footer {
        display: none; } }
  .App .error-message {
    color: var(--error-color);
    font-size: 0.85em; }
