.Loading {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 45;
  background-color: rgba(230, 230, 230, 0.9);
  display: flex;
  justify-content: center; }
  .Loading .wrapper {
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.8);
    width: 150px;
    margin: 20% auto 0;
    position: relative;
    padding: 48px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .Loading .wrapper .ms-Spinner-circle {
      height: 64px;
      width: 64px;
      border-width: 7.5px; }
