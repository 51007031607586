.ContributionForm {
  width: 85%;
  background-color: #fff;
  margin: 30px auto 256px;
  padding: 24px; }
  .ContributionForm .ms-Grid-col {
    margin-bottom: 16px; }
  .ContributionForm .payment-type-section {
    padding: 8px 24px;
    background-color: #f3f3f3; }
  .ContributionForm .contribution-actions-wrapper {
    position: fixed;
    background-color: #fff;
    bottom: -3px;
    z-index: 1;
    left: 0;
    right: 0;
    padding: 16px;
    box-shadow: 0 -1px 8px -1px rgba(0, 0, 0, 0.83); }
    .ContributionForm .contribution-actions-wrapper .actions {
      width: 85%;
      margin: 0 auto;
      text-align: right;
      flex-grow: 1; }
      .ContributionForm .contribution-actions-wrapper .actions .contribution-btn {
        margin-left: 16px; }
  @media screen and (max-width: 929px) {
    .ContributionForm {
      width: 100%; } }
