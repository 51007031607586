.ContactDetails {
  background-color: #fff; }
  .ContactDetails .contact-details-wrapper {
    margin: 8px 4px;
    display: flex;
    flex-direction: column;
    min-height: 250px; }
    .ContactDetails .contact-details-wrapper.source-contact {
      border: 1px solid rgba(44, 44, 44, 0.2);
      padding: 16px; }
    .ContactDetails .contact-details-wrapper.target-contact {
      border: 1px solid var(--info-color);
      padding: 16px; }
    .ContactDetails .contact-details-wrapper .contact-label {
      padding: 8px;
      margin: 4px 0; }
      .ContactDetails .contact-details-wrapper .contact-label:nth-child(odd) {
        background-color: #f6f6f6; }
  .ContactDetails .merge-actions {
    display: flex;
    justify-content: flex-end; }
    .ContactDetails .merge-actions .cancel-btn {
      margin-right: 16px; }
