.Contacts {
  background-color: #fff;
  width: 100%;
  margin: 0px auto 85px;
  padding: 16px 24px;
  box-sizing: border-box;
  position: relative;
  padding-left: 10%;
  padding-right: 10%;
  min-height: 600px; }
  .Contacts .fixed-search {
    position: fixed;
    top: 100px;
    background-color: #FFF;
    z-index: 20;
    border-bottom: 1px solid lightgrey;
    width: inherit;
    box-sizing: border-box;
    width: 79%;
    padding-bottom: 24px; }
  .Contacts header {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .Contacts .contacts-content .empty-contacts-view {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
    box-sizing: border-box; }
    .Contacts .contacts-content .empty-contacts-view > div {
      margin-left: 16px;
      box-sizing: border-box; }
  .Contacts .contacts-content .contacts-table {
    margin-top: 8px;
    box-sizing: border-box;
    border-top: 1px solid #999; }
  @media screen and (max-width: 1023px) {
    .Contacts {
      width: 100%; }
      .Contacts .contacts-content .contacts-table .contact-row .contact-details {
        margin-left: 8px;
        flex-wrap: wrap; }
        .Contacts .contacts-content .contacts-table .contact-row .contact-details .name-address {
          min-width: none; }
        .Contacts .contacts-content .contacts-table .contact-row .contact-details .phone-email {
          margin-left: 0; } }

.ContactDetailsPanel .ContactForm {
  padding-bottom: 220px; }
  .ContactDetailsPanel .ContactForm .chosen-household {
    border-radius: 15px;
    background-color: #f3f3f3;
    display: flex;
    align-items: center;
    padding: 0 8px;
    height: 24px; }
  .ContactDetailsPanel .ContactForm .ms-Grid-row {
    margin-bottom: 4px; }
  .ContactDetailsPanel .ContactForm .additional-field {
    background-color: #f3f3f3;
    padding: 4px 16px; }
  .ContactDetailsPanel .ContactForm .contact-details-actions {
    margin-top: 48px; }
    .ContactDetailsPanel .ContactForm .contact-details-actions .delete-btn {
      background-color: var(--error-color);
      color: '#fff'; }

.selected-contact-actions {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 61px; }
  .selected-contact-actions.hide {
    pointer-events: none; }
  .selected-contact-actions.show {
    pointer-events: all; }
  .selected-contact-actions .actions-inner {
    width: 85%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .selected-contact-actions .actions-inner.show {
      border: 1px solid rgba(55, 55, 55, 0.5);
      background-color: var(--info-color);
      box-sizing: border-box;
      height: 85px;
      padding: 16px 32px;
      transform: translate3d(0, 0, 0);
      transition: all 350ms var(--animation-curve);
      box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.16), 0 0 6px rgba(0, 0, 0, 0.23); }
    .selected-contact-actions .actions-inner.hide {
      transform: translate3d(0, 125px, 0);
      transition: all 350ms var(--animation-curve); }
    .selected-contact-actions .actions-inner h3 {
      color: white;
      font-weight: 400; }
