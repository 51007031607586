.EditReport {
  width: 85%;
  margin: 10px auto 256px; }
  .EditReport .ms-Grid-col {
    margin-bottom: 16px; }
  .EditReport .label-content {
    font-weight: 500; }
  .EditReport .beginning_balances,
  .EditReport .cover-page,
  .EditReport .contributions,
  .EditReport .loans,
  .EditReport .expenditures,
  .EditReport .investments,
  .EditReport .summary,
  .EditReport .indebtedness,
  .EditReport .addendum,
  .EditReport .debts-section {
    background-color: #fff;
    padding: 24px;
    margin-bottom: 16px; }
    .EditReport .beginning_balances header,
    .EditReport .cover-page header,
    .EditReport .contributions header,
    .EditReport .loans header,
    .EditReport .expenditures header,
    .EditReport .investments header,
    .EditReport .summary header,
    .EditReport .indebtedness header,
    .EditReport .addendum header,
    .EditReport .debts-section header {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .EditReport .beginning_balances .content-collapsed,
    .EditReport .cover-page .content-collapsed,
    .EditReport .contributions .content-collapsed,
    .EditReport .loans .content-collapsed,
    .EditReport .expenditures .content-collapsed,
    .EditReport .investments .content-collapsed,
    .EditReport .summary .content-collapsed,
    .EditReport .indebtedness .content-collapsed,
    .EditReport .addendum .content-collapsed,
    .EditReport .debts-section .content-collapsed {
      height: 0;
      overflow: hidden; }
    .EditReport .beginning_balances .content-expanded,
    .EditReport .cover-page .content-expanded,
    .EditReport .contributions .content-expanded,
    .EditReport .loans .content-expanded,
    .EditReport .expenditures .content-expanded,
    .EditReport .investments .content-expanded,
    .EditReport .summary .content-expanded,
    .EditReport .indebtedness .content-expanded,
    .EditReport .addendum .content-expanded,
    .EditReport .debts-section .content-expanded {
      height: auto;
      overflow: auto; }
  .EditReport .form-label {
    font-size: 1.5em;
    margin-top: 5px;
    margin-right: 24px;
    font-weight: 300;
    text-transform: lowercase; }
  .EditReport .money-label {
    min-height: 48px; }
  .EditReport .money-input {
    margin-top: 16px;
    box-sizing: border-box; }
    .EditReport .money-input.with-label {
      margin-top: -16px; }
  .EditReport .section-actions {
    padding-top: 16px;
    text-align: right; }

.edit-report-actions {
  position: fixed;
  background-color: #fff;
  bottom: -3px;
  z-index: 1;
  left: 0;
  right: 0;
  padding: 16px;
  box-shadow: 0 -1px 8px -1px rgba(0, 0, 0, 0.83); }
  .edit-report-actions .actions {
    width: 85%;
    margin: 0 auto;
    text-align: right; }
    .edit-report-actions .actions .action-btn {
      font-size: 0.8em;
      margin-left: 16px; }
      .edit-report-actions .actions .action-btn.delete-btn {
        background-color: var(--error-color);
        color: '#fff'; }
  @media screen and (max-width: 1024px) {
    .edit-report-actions {
      display: flex; }
      .edit-report-actions .action-btn.delete-btn {
        float: none; } }
  @media screen and (max-width: 689px) {
    .edit-report-actions .action-btn {
      margin: 8px 0; } }
  @media screen and (max-width: 479px) {
    .edit-report-actions .action-btn {
      width: 100%; }
      .edit-report-actions .action-btn.delete-btn {
        margin-top: 16px; } }
  .edit-report-actions .ms-DetailsHeader {
    height: 50px; }
