.contact-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 8px 12px;
  border-bottom: 1px solid #f3f3f3;
  margin-bottom: 2px;
  box-sizing: border-box;

  &:hover {
    background-color: #c7e0f4;
    transition: all ease-in-out 155ms;
  }

  &.selected {
    background-color: #c7e0f4;
  }

  & > div:not(.checkbox-row) {
    flex: 1;
  }

  .contact-details {
    margin-left: 32px;
    box-sizing: border-box;
    display: flex;
    align-items: stretch;

    h4 {
      font-weight: 400;
      font-size: 1.25em;
      margin: 0;
      padding: 0;
    }

    address p {
      margin: 0 2px;
      padding: 0;
      font-style: normal;
      font-weight: 300;
    }

    .name-address {
      min-width: 200px;
    }

    .phone-email {
      margin-left: 32px;
    }
  }
}
