.AddResponsiblePartyDialog {
  width: 55%;
  min-width: 55% !important;
  max-width: 55% !important;
  overflow: hidden !important; }
  .AddResponsiblePartyDialog > div:first-of-type {
    width: 100%; }
  .AddResponsiblePartyDialog .ms-Dialog-inner {
    overflow: hidden !important;
    width: auto; }
    .AddResponsiblePartyDialog .ms-Dialog-inner .add-responsible-party {
      padding: 24px 24px 64px;
      position: relative;
      height: 300px;
      overflow: auto;
      width: auto; }
  .AddResponsiblePartyDialog .footer {
    padding-top: 16px;
    border-top: 1px solid #999;
    height: auto;
    width: 100%;
    text-align: right; }
