.Signup-wrapper {
  overflow: auto;
  height: 95vh; }
  .Signup-wrapper .cancel-btn {
    margin-right: 8px; }
  .Signup-wrapper .Signup {
    background-color: #fff;
    margin: 60px auto 128px;
    padding: 48px;
    max-width: 840px;
    box-sizing: border-box; }
    @media screen and (max-width: 639px) {
      .Signup-wrapper .Signup {
        width: 98vw;
        position: absolute;
        left: 1vw;
        top: 0;
        max-width: none;
        height: 94vh;
        margin: 0;
        padding-bottom: 128px;
        overflow: auto; } }
    .Signup-wrapper .Signup .logo {
      display: flex;
      justify-content: center;
      margin-bottom: 32px; }
    .Signup-wrapper .Signup .ms-Grid-row {
      margin-bottom: 8px; }
