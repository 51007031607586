.EditFilerDetails {
  background-color: #fff;
  width: 100%;
  height: auto;
  position: relative;
  padding: 24px;
  box-sizing: border-box; }
  .EditFilerDetails h3 {
    margin: 0 0 16px 0;
    padding: 0 0 8px 0;
    border-bottom: 1px solid #999; }
  .EditFilerDetails .edit-filer-details-content {
    display: flex;
    justify-content: space-between;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    height: 0;
    overflow: hidden; }
    .EditFilerDetails .edit-filer-details-content.expanded {
      height: auto;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }
    @media screen and (max-width: 753px) {
      .EditFilerDetails .edit-filer-details-content {
        flex-direction: column; } }
    .EditFilerDetails .edit-filer-details-content .office-info {
      flex: 1;
      border: 1px solid #ddd;
      border-bottom: 1px solid #999;
      padding: 16px;
      background-color: #f4f4f4; }
    .EditFilerDetails .edit-filer-details-content .filer-info {
      flex: 2;
      padding: 16px 64px; }
      @media screen and (max-width: 753px) {
        .EditFilerDetails .edit-filer-details-content .filer-info {
          padding: 16px; } }
      .EditFilerDetails .edit-filer-details-content .filer-info .ms-Grid-col {
        margin-bottom: 16px; }
      .EditFilerDetails .edit-filer-details-content .filer-info .incumbent-checkbox {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 8px; }
