.EditImportRowDialogBody {
  max-height: 60vh;
  overflow: auto;

  .edit-import-row-errors {
    padding-left: 1rem;
    margin: 0;

    li:not(:first-of-type) {
      margin-top: 0.5rem;
    }
  }

  .edit-import-row-error-message {
    color: var(--error-color);
  }

  .edit-import-row-error-message-fixed {
    text-decoration: line-through;
  }

  .edit-import-row-success-message {
    color: var(--success-color);
  }

  .edit-import-row-input-container {
    margin-top: 1rem;
  }

  .edit-import-row-input {
    margin-top: 0.75rem;
  }

  .edit-import-row-input-success-message {
    color: var(--success-color);
    padding-top: 5px;
  }
}

.EditImportRowDialogBody__Footer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}