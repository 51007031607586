.EditBillingInfo {
  padding: 24px;
  margin: 30px auto 0;
  background-color: #fff;
  width: 85%; }
  .EditBillingInfo .logo {
    display: flex;
    align-items: center; }
    .EditBillingInfo .logo h3 {
      margin-left: 48px; }
  .EditBillingInfo .update-billing-info-actions {
    display: flex;
    justify-content: flex-end;
    padding: 24px 32px; }
    .EditBillingInfo .update-billing-info-actions .cancel-btn {
      margin-right: 16px; }

.CancelAccount {
  background-color: #fff;
  margin: 24px auto 500px;
  width: 85%;
  padding: 24px; }
  .CancelAccount .cancel-header {
    display: flex;
    align-items: center; }
  .CancelAccount .edit-billing-info-actions {
    display: flex;
    justify-content: flex-end; }
