.AggregatesGraph {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0;
  width: 100%;
  padding-left: 8px; }
  .AggregatesGraph .aggregates-header {
    margin: 0 0 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 100%; }
    .AggregatesGraph .aggregates-header h2 {
      font-size: 16px;
      margin-left: 16px;
      box-sizing: border-box;
      margin-top: 6px;
      margin-bottom: 0; }
  .AggregatesGraph .aggregate-list {
    width: 98%; }
  .AggregatesGraph .AggregateRow {
    box-sizing: border-box;
    padding: 4px 0 6px 10px;
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(25, 25, 25, 0.05); }
    .AggregatesGraph .AggregateRow:first-of-type {
      border-top: none; }
    .AggregatesGraph .AggregateRow h4 {
      min-width: 130px;
      box-sizing: border-box;
      margin: 0; }
    .AggregatesGraph .AggregateRow span {
      font-weight: bold;
      font-size: 14px;
      text-align: right;
      min-width: 75px;
      margin: 0;
      padding: 0;
      box-sizing: border-box; }
