.EditCandidateDetails {
  background-color: #fff;
  width: 100%;
  height: auto;
  position: relative;
  padding: 24px;
  margin-top: 24px;
  box-sizing: border-box; }
  .EditCandidateDetails h3 {
    margin: 0 0 16px 0;
    padding: 0 0 8px 0;
    border-bottom: 1px solid #999; }
  .EditCandidateDetails .edit-candidate-details-content {
    height: 0;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 100%; }
    .EditCandidateDetails .edit-candidate-details-content .ms-Grid-col {
      margin-bottom: 16px; }
    .EditCandidateDetails .edit-candidate-details-content.expanded {
      height: auto;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }
