.Login {
  background-color: #fff;
  margin: 60px auto 0;
  padding: 24px;
  max-width: 600px;
  box-sizing: border-box; }
  .Login .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 32px; }
  @media screen and (max-width: 639px) {
    .Login {
      width: 98vw;
      position: absolute;
      margin: 0;
      height: 94vh;
      left: 1vw;
      top: 1vh;
      bottom: 1vh;
      max-width: none; } }
  .Login .ms-Grid-row .ms-Grid-col {
    margin-bottom: 16px; }
  .Login .ms-Grid-row .ms-TextField-wrapper .ms-TextField-fieldGroup {
    background: #fff; }
  .Login .links {
    padding-top: 24px; }
    .Login .links span {
      margin: 0 8px; }
