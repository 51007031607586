@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700");
:root {
  --info-color: #71afe5;
  --primary-color: #244b8d;
  --success-color: #107c10;
  --error-color: #a80000;
  --warning-color: #ff8c00;
  --disabled-color: #c6c5ca;
  --error-icon-color: #e81123;
  --animation-curve: cubic-bezier(0.87, -0.41, 0.19, 1.44); }

html
*:not([class*='fa-']):not([class*='icon-']):not([data-icon-name]):not([class*='override-base-font']) {
  font-family: 'Open Sans', sans-serif !important; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Segoe', sans-serif;
  background-color: #999;
  background-image: linear-gradient(to bottom, #999 0%, #8a8a8a 100%);
  overflow-x: hidden;
  height: 100vh; }

.ms-Overlay {
  background-color: rgba(0, 0, 0, 0.7); }

.depth-1 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-sizing: border-box; }

.go-back {
  background: linear-gradient(to right, #636363 calc(100%), #63636399 20px);
  position: relative;
  top: 2px;
  left: 0;
  right: 0;
  box-shadow: 0 1px rgba(0, 0, 0, 0.2);
  z-index: 28; }
  @media print {
    .go-back {
      display: none; } }
  @media screen and (max-width: 400px) {
    .go-back {
      background-color: #636363; } }

.error-message {
  color: var(--error-color);
  font-size: 0.85em; }

[data-icon-name='Down'] {
  font-family: 'Font Awesome 5 Free' !important;
  font-style: normal;
  position: relative; }
  [data-icon-name='Down']::after {
    content: '\f063';
    color: #333;
    font-weight: 900; }

[data-icon-name='Up'] {
  font-family: 'Font Awesome 5 Free' !important;
  font-style: normal;
  position: relative; }
  [data-icon-name='Up']::after {
    content: '\f062';
    color: #333;
    font-weight: 900; }

[data-icon-name='StatusCircleCheckmark'] {
  font-family: 'Font Awesome 5 Free' !important;
  font-style: normal;
  position: relative; }
  [data-icon-name='StatusCircleCheckmark']::after {
    content: '\f058';
    font-weight: 900;
    font-size: 13px;
    margin-right: 1px !important; }

[data-icon-name='CheckMark'] {
  font-family: 'Font Awesome 5 Free' !important;
  font-style: normal;
  position: relative; }
  [data-icon-name='CheckMark']::after {
    content: '\f00c';
    font-weight: 900;
    font-size: 13px;
    margin-right: 1px !important; }

[data-icon-name='Cancel'] {
  font-family: 'Font Awesome 5 Free' !important;
  font-style: normal;
  position: relative; }
  [data-icon-name='Cancel']::after {
    content: '\f00d';
    font-weight: 900;
    font-size: 13px;
    margin-right: 1px !important; }

[data-icon-name='ErrorBadge'] {
  font-family: 'Font Awesome 5 Free' !important;
  font-style: normal;
  position: relative; }
  [data-icon-name='ErrorBadge']::after {
    content: '\f057';
    font-weight: 900;
    font-size: 13px;
    margin-right: 1px !important; }

[data-icon-name='Completed'] {
  font-family: 'Font Awesome 5 Free' !important;
  font-style: normal;
  position: relative; }
  [data-icon-name='Completed']::after {
    content: '\f058';
    font-weight: 900;
    font-size: 13px;
    margin-right: 1px !important; }

[data-icon-name='Calendar'] {
  font-family: 'Font Awesome 5 Free' !important;
  font-style: normal;
  position: relative; }
  [data-icon-name='Calendar']::after {
    content: '\f073';
    font-weight: 900;
    font-size: 19px;
    margin-right: 1px !important; }
