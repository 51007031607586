.ViewAllLoans {
  margin: 45px auto auto;
  padding: 24px;
  background-color: #fff;
  width: 85%; }
  @media screen and (max-width: 1024px) {
    .ViewAllLoans {
      width: 100%;
      margin-top: 0px;
      padding: 8px; } }
  .ViewAllLoans .loans-header {
    display: flex;
    justify-content: space-between;
    align-items: center; }

.compact-loan-details {
  padding: 16px; }

.expanded-loan-details {
  padding: 16px; }
