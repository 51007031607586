.VerifyFECReport {
  background-color: #fff;
  margin: 10px auto 30px;
  width: 65%;
  padding: 24px; }
  .VerifyFECReport .verify-fec-report-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  .VerifyFECReport pre.override-base-font-for-pre {
    font-family: monospace !important;
    font-size: 0.8rem; }
  .VerifyFECReport .verify-button {
    margin-right: 5px; }
