.AddReconciliations-wrapper {
  width: 100%;
  height: auto;
  margin-top: 40px;
  display: flex;
  align-items: stretch;
  background-color: #fff; }
  .AddReconciliations-wrapper .options-panel {
    width: 300px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #dadada;
    position: absolute;
    height: calc(100vh - 164px);
    overflow-y: auto; }
    .AddReconciliations-wrapper .options-panel .rec-field {
      margin-bottom: 16px; }
  .AddReconciliations-wrapper .reconciliation-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 24px; }
    .AddReconciliations-wrapper .reconciliation-actions .selected-deposits {
      color: green; }
    .AddReconciliations-wrapper .reconciliation-actions .selected-expenditures {
      color: red; }
    .AddReconciliations-wrapper .reconciliation-actions label {
      align-self: flex-start;
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 4px; }
      .AddReconciliations-wrapper .reconciliation-actions label span {
        margin-left: 16px;
        color: #333;
        font-weight: bold; }
      .AddReconciliations-wrapper .reconciliation-actions label:last-of-type {
        margin-bottom: 20px;
        border-bottom: 1px solid #f3f3f3;
        padding-bottom: 4px; }
  .AddReconciliations-wrapper .select-all-checkbox {
    position: absolute;
    top: 118px;
    left: 12px;
    z-index: 99; }
  .AddReconciliations-wrapper.ms-hiddenSm .reconciliations-list {
    left: 300px;
    right: 0px;
    box-sizing: border-box;
    background-color: #fff;
    position: absolute;
    height: calc(100vh - 158px); }
    .AddReconciliations-wrapper.ms-hiddenSm .reconciliations-list .list-wrapper {
      padding-right: 24px; }
    .AddReconciliations-wrapper.ms-hiddenSm .reconciliations-list .search-actions {
      margin-top: 8px;
      display: flex;
      box-sizing: border-box;
      justify-content: flex-start;
      align-items: center;
      padding-left: 16px;
      width: 100%; }
      .AddReconciliations-wrapper.ms-hiddenSm .reconciliations-list .search-actions .search-box {
        margin-right: 16px;
        width: 50%; }
  .AddReconciliations-wrapper .out-of-balance-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    padding: 8px; }
  .AddReconciliations-wrapper.ms-hiddenMdUp {
    display: flex;
    flex-direction: column;
    padding: 0 16px 44px;
    box-sizing: border-box; }
    .AddReconciliations-wrapper.ms-hiddenMdUp .reconciliation-actions {
      margin-top: 32px; }
    .AddReconciliations-wrapper.ms-hiddenMdUp .reconciliations-list {
      left: 0px;
      right: 0px;
      box-sizing: border-box;
      background-color: #fff;
      position: absolute;
      top: 260px;
      bottom: 60px; }
      .AddReconciliations-wrapper.ms-hiddenMdUp .reconciliations-list .list-wrapper {
        padding-right: 24px; }
      .AddReconciliations-wrapper.ms-hiddenMdUp .reconciliations-list .search-actions {
        margin-top: 8px;
        display: flex;
        box-sizing: border-box;
        justify-content: flex-start;
        align-items: center;
        padding-left: 16px;
        width: 100%; }
        .AddReconciliations-wrapper.ms-hiddenMdUp .reconciliations-list .search-actions .search-box {
          margin-right: 16px;
          width: 50%; }
