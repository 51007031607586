.TermsAndConditionsDialog {
  width: 960px;
  min-width: 375px;
  max-width: 960px !important;
  height: 64vh;
  overflow: hidden !important; }
  @media screen and (max-width: 500px) {
    .TermsAndConditionsDialog {
      height: 100vh; } }
  .TermsAndConditionsDialog .ms-Dialog-inner {
    overflow: hidden !important; }
    .TermsAndConditionsDialog .ms-Dialog-inner .terms-and-conditions {
      padding-bottom: 24px;
      position: relative;
      height: 250px;
      overflow: auto; }
    .TermsAndConditionsDialog .ms-Dialog-inner .footer {
      padding-top: 16px;
      border-top: 1px solid #999;
      height: auto;
      width: 100%;
      text-align: right; }
      .TermsAndConditionsDialog .ms-Dialog-inner .footer .cancel-btn {
        margin-right: 8px; }
