.IntegrationSettingForm {
  background-color: #fff;
  margin: 24px auto 24px;
  position: relative; }
  @media screen and (max-width: 1024px) {
    .IntegrationSettingForm {
      width: 100%;
      margin-top: 0;
      padding: 8px; } }
  @media screen and (min-width: 1025px) and (max-width: 1920px) {
    .IntegrationSettingForm {
      width: 95%; } }
  .IntegrationSettingForm .actions {
    margin: 0 auto;
    text-align: right;
    flex-grow: 1;
    background-color: #fff; }
    .IntegrationSettingForm .actions .integration-setting-btn {
      margin-left: 16px; }
