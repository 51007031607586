.create-new-depository-account-wrapper {
  border-top: 1px solid #919191;
  padding-top: 8px;
  margin-top: 8px;
  box-sizing: border-box; }

.selected-depository-account {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 16px; }
  .selected-depository-account .selected-depository-account-actions {
    display: flex;
    width: 75%;
    justify-content: space-around;
    align-items: center; }

.edit-selected-depository-account-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 16px; }
