.PasswordField {
  position: relative;
  width: 100%;
  display: inline-block; }
  .PasswordField .visibility-button {
    position: absolute;
    top: 29px;
    right: 3px;
    z-index: 999; }
  .PasswordField.underlined .visibility-button {
    top: 2px; }
