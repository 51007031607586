.MainDashboard {
  margin: 16px 0;
  padding: 16px;
  font-size: 0.9em; }
  .MainDashboard .dashboard-content {
    display: flex;
    margin: 0 auto;
    width: 98%;
    max-width: 1535px;
    box-sizing: border-box;
    flex-wrap: wrap; }
    @media screen and (max-width: 639px) {
      .MainDashboard .dashboard-content {
        flex-direction: column; } }
  .MainDashboard h2 {
    font-weight: 400;
    padding: 0 0 8px;
    margin: 0;
    font-size: 0.9rem; }
  .MainDashboard h3 {
    font-size: 0.7rem; }
  .MainDashboard .divider {
    height: 1px;
    background-color: #999; }
  .MainDashboard .campaign-info {
    flex: 1;
    order: 1;
    background-color: #fff;
    padding: 8px 16px;
    margin: 0 16px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch; }
    @media screen and (max-width: 639px) {
      .MainDashboard .campaign-info {
        margin-right: 0; } }
    .MainDashboard .campaign-info .campaign-logo {
      display: flex;
      align-items: center;
      justify-content: center; }
    .MainDashboard .campaign-info .campaign-details {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: auto; }
      .MainDashboard .campaign-info .campaign-details h2,
      .MainDashboard .campaign-info .campaign-details h3 {
        text-align: center; }
  .MainDashboard .next-report {
    flex: 1;
    order: 3;
    margin-left: 16px;
    background-color: #fff;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media screen and (max-width: 889px) {
      .MainDashboard .next-report {
        order: 2;
        flex: 1; } }
    @media screen and (max-width: 639px) {
      .MainDashboard .next-report {
        flex-direction: column;
        margin-left: 0;
        margin-top: 16px; } }
    .MainDashboard .next-report .report {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 16px 8px;
      flex: 1; }
      .MainDashboard .next-report .report .reporting-period {
        display: flex;
        align-items: center; }
        .MainDashboard .next-report .report .reporting-period h2 {
          margin-left: 16px; }
    .MainDashboard .next-report .file-report-actions {
      margin-top: 32px; }
  .MainDashboard .finance-content {
    order: 2;
    background-color: #fff;
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    flex: 2;
    font-size: .9rem;
    color: #323130;
    position: relative; }
    .MainDashboard .finance-content .refresh-info {
      position: absolute;
      top: 0px;
      right: 24px;
      font-size: 12px;
      padding-top: 16px; }
      .MainDashboard .finance-content .refresh-info .refresh-button {
        display: inline-block;
        font-size: 12px; }
    @media screen and (max-width: 840px) {
      .MainDashboard .finance-content {
        order: 3;
        flex: 12 0 100%;
        margin-top: 16px; } }
    .MainDashboard .finance-content .finances {
      padding: 16px 32px;
      display: flex;
      flex-direction: column;
      display: inline-block;
      width: 80%;
      margin: 0 auto;
      font-size: 0.9rem; }
    .MainDashboard .finance-content .secondary-actions {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 16px;
      padding-bottom: 8px; }
      @media screen and (max-width: 840px) and (min-width: 640px) {
        .MainDashboard .finance-content .secondary-actions {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start; } }
  .MainDashboard .finance-content-old {
    order: 2;
    background-color: #fff;
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    flex: 2;
    position: relative; }
    .MainDashboard .finance-content-old .refresh-button {
      position: absolute;
      top: 0px;
      right: 24px;
      font-size: 12px; }
    @media screen and (max-width: 840px) {
      .MainDashboard .finance-content-old {
        order: 3;
        flex: 12 0 100%;
        margin-top: 16px; } }
    .MainDashboard .finance-content-old .finances {
      padding: 16px 32px;
      display: flex;
      flex-direction: column;
      display: inline-block;
      width: 65%;
      margin: 0 auto; }
    .MainDashboard .finance-content-old .money-raised-chart {
      padding: 16px 32px;
      width: auto;
      display: flex;
      justify-content: center; }
    .MainDashboard .finance-content-old .no-money-raised {
      display: flex; }
      .MainDashboard .finance-content-old .no-money-raised p {
        color: #000000aa;
        font-size: 0.8rem;
        width: 150px;
        margin-left: 16px; }
    .MainDashboard .finance-content-old .secondary-actions {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 16px;
      padding-bottom: 8px; }
      @media screen and (max-width: 840px) and (min-width: 640px) {
        .MainDashboard .finance-content-old .secondary-actions {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start; } }
  .MainDashboard .dashboard-actions {
    order: 4;
    width: 100%;
    background-color: #fff;
    flex: 12 0 100%;
    margin-top: 16px; }
    .MainDashboard .dashboard-actions .site-actions {
      padding: 32px;
      display: flex;
      justify-content: space-between; }
      @media screen and (max-width: 1023px) {
        .MainDashboard .dashboard-actions .site-actions {
          flex-direction: column; } }
      .MainDashboard .dashboard-actions .site-actions .sub-col {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        border-right: 1px solid #ddd;
        padding-left: 16px;
        padding-right: 8px; }
        .MainDashboard .dashboard-actions .site-actions .sub-col:last-of-type {
          border-right: none; }
        .MainDashboard .dashboard-actions .site-actions .sub-col:first-of-type {
          padding-left: 0; }
        .MainDashboard .dashboard-actions .site-actions .sub-col .action-btn,
        .MainDashboard .dashboard-actions .site-actions .sub-col div .action-btn {
          width: 95%;
          box-sizing: border-box;
          margin: 4px;
          padding: 16px 8px;
          flex: 1; }
        @media screen and (max-width: 1023px) {
          .MainDashboard .dashboard-actions .site-actions .sub-col {
            padding: 0;
            border: none;
            border-bottom: 1px solid #999;
            align-items: center;
            padding: 8px 0; } }

.inactive-account {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 65px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99; }
  .inactive-account h3 {
    color: #fff;
    font-size: 36px; }
  .inactive-account p {
    color: #fff;
    font-size: 24px; }
    .inactive-account p button {
      color: #fff;
      text-decoration: underline;
      font-weight: bold; }
      .inactive-account p button:hover {
        color: gray; }
