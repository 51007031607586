.EditCampaign {
  margin: 0 auto;
  width: 100%;
  height: 81vh; }
  @media screen and (max-width: 639px) {
    .EditCampaign {
      width: 100%; } }
  .EditCampaign .container {
    width: 100%;
    padding: 24px 24px 512px;
    position: relative;
    box-sizing: border-box; }
    .EditCampaign .container h1 {
      font-weight: 300;
      margin: 0;
      padding: 0; }
    .EditCampaign .container .section {
      background-color: #fff; }
  .EditCampaign .action-footer {
    height: 65px;
    position: fixed;
    left: 0;
    right: 0;
    background-color: #f4f4f4;
    bottom: 0;
    border: 1px solid rgba(0, 0, 0, 0.83);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 24px;
    box-sizing: border-box;
    z-index: 55; }
    .EditCampaign .action-footer .cancel-btn {
      margin-right: 16px; }
