.FECReportEdit {
  width: 95%;
  margin: 20px auto; }
  .FECReportEdit h3 {
    border-bottom: 2px solid rgba(0, 0, 0, 0.87); }
  .FECReportEdit label[class^='ms-Label'] {
    font-weight: 900 !important; }
  .FECReportEdit .section-actions {
    padding: 16px 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  .FECReportEdit .fec-report-section {
    background-color: white;
    padding: 24px;
    margin-bottom: 16px; }
    .FECReportEdit .fec-report-section [class$='-expanded'] {
      display: block; }
    .FECReportEdit .fec-report-section [class$='-collapsed'] {
      display: none; }
    .FECReportEdit .fec-report-section .sub-question {
      padding-left: 32px; }
    .FECReportEdit .fec-report-section .entry-row {
      padding-top: 16px;
      padding-bottom: 16px; }
