.AnedotContributions {
  width: 100%;
  padding: 16px 24px;
  margin: 0px auto 45px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap; }
  .AnedotContributions .DataLabel {
    text-transform: capitalize; }
  .AnedotContributions .chosen-contact {
    border-radius: 15px;
    background-color: #e5e5e5;
    display: flex;
    align-items: center;
    height: 24px; }
  .AnedotContributions .contribution-wrapper {
    box-sizing: border-box;
    flex: 1;
    background-color: #91919111;
    margin-right: 16px;
    padding: 0px 24px 16px 24px; }
    .AnedotContributions .contribution-wrapper.show-deleted {
      background-color: #a8000011; }
    .AnedotContributions .contribution-wrapper h3 {
      margin-bottom: 0; }
    .AnedotContributions .contribution-wrapper .navigation {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px; }
    .AnedotContributions .contribution-wrapper address {
      font-style: normal;
      box-sizing: border-box; }
      .AnedotContributions .contribution-wrapper address p {
        box-sizing: border-box;
        margin: 4px 0;
        font-style: normal; }
    .AnedotContributions .contribution-wrapper .anedot-contribution-actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      margin-top: 24px; }
  .AnedotContributions .campaign-details .split-actions {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  .AnedotContributions .contact-matching-wrapper {
    box-sizing: border-box;
    flex: 3;
    padding: 0 24px 16px;
    width: 100%; }
  .AnedotContributions .possible-contact-actions {
    display: flex;
    flex-direction: column;
    width: 100%; }
    .AnedotContributions .possible-contact-actions .ms-ChoiceField-wrapper {
      width: 100%; }
    .AnedotContributions .possible-contact-actions .use-existing {
      width: 100%; }
      .AnedotContributions .possible-contact-actions .use-existing .existing-list {
        margin-top: 16px;
        width: 100%; }
        .AnedotContributions .possible-contact-actions .use-existing .existing-list .contact-row {
          width: 100%; }
  .AnedotContributions .search-contacts {
    display: flex;
    align-items: center; }
  .AnedotContributions .newContact {
    margin-top: 16px; }

.no-anedot-transactions {
  width: 65%;
  box-sizing: border-box;
  width: 65%;
  padding: 24px;
  margin: 15px auto 45px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .no-anedot-transactions .message-container {
    display: flex;
    align-items: center;
    justify-content: center; }
  .no-anedot-transactions p {
    font-size: 18px;
    margin-left: 24px; }
