h2.DataLabel {
  font-weight: 100;
  font-size: 0.9rem;
  display: flex; }
  h2.DataLabel span {
    display: inline-block; }
  h2.DataLabel .label {
    font-weight: 300; }

h3.DataLabel {
  font-weight: 100;
  font-size: 0.7rem;
  display: flex; }
  h3.DataLabel span {
    display: inline-block; }
  h3.DataLabel .label {
    font-weight: 300; }

h4.DataLabel {
  font-weight: 100;
  font-size: 0.8rem;
  display: flex; }
  h4.DataLabel span {
    display: inline-block; }
  h4.DataLabel .label {
    font-weight: 300; }
