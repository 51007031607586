.SpecificList {
  width: 100%;
  height: auto;
  margin-top: 40px;
  display: flex;
  align-items: stretch; }
  .SpecificList .options-panel {
    width: 300px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #dadada;
    position: absolute;
    height: calc(100vh - 164px);
    overflow-x: hidden; }
  .SpecificList .list {
    left: 300px;
    right: 0;
    box-sizing: border-box;
    background-color: #fff;
    height: calc(100vh - 164px);
    position: absolute; }
    .SpecificList .list.loading-csv {
      padding-top: 165px; }
    .SpecificList .list .loading-list {
      padding-top: 1px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -300px; }
