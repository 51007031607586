.CreateCampaign {
  background-color: #fff;
  margin: 30px auto 0;
  padding: 24px;
  max-width: 960px;
  box-sizing: border-box; }
  @media screen and (max-width: 500px) {
    .CreateCampaign {
      width: 100vw;
      position: absolute;
      left: 0;
      top: 0;
      max-width: none; }
      .CreateCampaign .content {
        position: relative;
        padding-bottom: 256px; } }

.logout-btn {
  position: absolute;
  left: 10px;
  top: 10px; }
