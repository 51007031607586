.ExpenditureForm {
  width: 85%;
  background-color: #fff;
  margin: 30px auto 256px;
  padding: 24px; }
  .ExpenditureForm .ms-Grid-col {
    margin-bottom: 16px; }
  .ExpenditureForm .payment-type-section {
    padding: 8px 24px;
    background-color: #f3f3f3; }
  .ExpenditureForm .expenditure-actions-wrapper {
    position: fixed;
    background-color: #fff;
    bottom: -3px;
    z-index: 1;
    left: 0;
    right: 0;
    padding: 16px;
    box-shadow: 0 -1px 8px -1px rgba(0, 0, 0, 0.83); }
    .ExpenditureForm .expenditure-actions-wrapper .actions {
      width: 85%;
      margin: 0 auto;
      text-align: right; }
      .ExpenditureForm .expenditure-actions-wrapper .actions .expenditure-btn {
        margin-left: 16px; }
  .ExpenditureForm .recipient {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #fff;
    color: #fff;
    background-color: rgba(0, 120, 212, 0.7);
    padding: 0 16px; }
  .ExpenditureForm .chosen-contact {
    border-radius: 15px;
    background-color: #e5e5e5;
    display: flex;
    align-items: center;
    height: 24px; }
  @media screen and (max-width: 929px) {
    .ExpenditureForm {
      width: 100%; } }
