.LongRunningServiceDialog .file-submit-spinner {
  margin-left: 10px; }

.LongRunningServiceDialog .fec-with-warnings-checkbox {
  display: block;
  margin-top: 10px; }

.LongRunningServiceDialog .info {
  color: #4d4d4d; }

.LongRunningServiceDialog .success {
  color: #099101; }

.LongRunningServiceDialog .error {
  color: #a80000; }
