body {
  background: #999 !important; }

.DepositTicket {
  background-color: #fff;
  margin: 30px auto;
  width: 85%;
  padding: 30px 16px;
  height: auto;
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }
  @media print {
    .DepositTicket {
      box-shadow: none;
      width: 100%;
      margin-top: 16px; } }
  .DepositTicket .wrapper {
    display: flex; }
    .DepositTicket .wrapper .content {
      display: flex;
      flex: 1; }
      .DepositTicket .wrapper .content .header {
        writing-mode: tb-rl;
        transform: rotate(-180deg);
        margin: 0;
        box-sizing: border-box;
        font-size: 0.85rem;
        height: 300px;
        width: 64px;
        text-align: center; }
      .DepositTicket .wrapper .content .campaign-details {
        padding: 0 16px 16px;
        margin: 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column; }
        .DepositTicket .wrapper .content .campaign-details h3 {
          font-weight: bold;
          margin: 0; }
        .DepositTicket .wrapper .content .campaign-details address p {
          margin: 0 2px;
          font-style: normal; }
        .DepositTicket .wrapper .content .campaign-details .date {
          margin-top: 32px; }
          .DepositTicket .wrapper .content .campaign-details .date span {
            font-weight: 600;
            margin-right: 8px; }
        .DepositTicket .wrapper .content .campaign-details .bank-info {
          margin-top: 32px;
          visibility: hidden; }
    .DepositTicket .wrapper .deposits-list {
      flex: 1;
      display: flex;
      justify-content: flex-end; }
      .DepositTicket .wrapper .deposits-list table {
        border: 1px solid #919191;
        width: 80%;
        border-collapse: collapse; }
        .DepositTicket .wrapper .deposits-list table tbody tr td {
          border: 1px solid #919191;
          padding: 6px; }
          .DepositTicket .wrapper .deposits-list table tbody tr td.label-cell {
            background-color: #9a9a9a;
            color: white; }
            @media print {
              .DepositTicket .wrapper .deposits-list table tbody tr td.label-cell {
                color: #919191;
                background-color: white; } }
          .DepositTicket .wrapper .deposits-list table tbody tr td p {
            margin: 2px; }
  .DepositTicket .deposit-ticket-footer {
    width: 100%;
    text-align: center;
    font-size: 0.8rem; }

.DepositTicket-actions {
  width: 85%;
  margin: 20px auto 80px;
  text-align: center;
  background-color: white;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }
  @media print {
    .DepositTicket-actions {
      display: none;
      box-shadow: none; } }
