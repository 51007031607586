@keyframes slideinfromright {
  from {
    transform-origin: 0 0;
    transform: translate3d(550px, 0, 0); }
  to {
    transform: translate3d(15px, 0, 0); } }

@keyframes slideinfromleft {
  from {
    transform-origin: 0 0;
    transform: translate3d(-550px, 0, 0); }
  to {
    transform: translate3d(15px, 0, 0); } }

@keyframes slideinfromtop {
  from {
    transform-origin: 0 0;
    transform: translate3d(0, -550px, 0); }
  to {
    transform: translate3d(0, 15px, 0); } }

@keyframes slideinfrombottom {
  from {
    transform-origin: 0 0;
    transform: translate3d(0, 550px, 0); }
  to {
    transform: translate3d(0, 15px, 0); } }

.Toast {
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: absolute;
  background-color: #fff;
  height: auto;
  width: 300px;
  padding: 16px 24px 16px 16px;
  z-index: 10000000;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  color: #fff; }
  .Toast.top-right {
    top: 15px;
    right: 15px;
    animation: slideinfromright 500ms var(--animation-curve) 0ms; }
  .Toast.top-left {
    top: 15px;
    left: 15px;
    animation: slideinfromleft 500ms var(--animation-curve) 0ms; }
  .Toast.top-center {
    top: 15px;
    left: calc(50% - 150px);
    justify-content: center;
    animation: slideinfromtop 500ms var(--animation-curve) 0ms; }
  .Toast.bottom-right {
    bottom: 15px;
    right: 15px;
    animation: slideinfromright 500ms var(--animation-curve) 0ms; }
  .Toast.bottom-left {
    bottom: 15px;
    left: 15px;
    animation: slideinfromleft 500ms var(--animation-curve) 0ms; }
  .Toast.bottom-center {
    bottom: 25px;
    left: calc(50% - 150px);
    justify-content: center;
    animation: slideinfrombottom 500ms var(--animation-curve) 0ms; }
  .Toast.INFO {
    background-color: var(--info-color); }
  .Toast.SUCCESS {
    background-color: var(--success-color); }
  .Toast.ERROR {
    background-color: var(--error-color); }
  .Toast.WARNING {
    background-color: var(--warning-color); }
  .Toast p {
    margin-left: 16px; }
