.EditOrganizationsSection {
  background-color: #fff;
  width: 100%;
  height: auto;
  position: relative;
  padding: 24px;
  margin-top: 24px;
  box-sizing: border-box; }
  .EditOrganizationsSection h3 {
    margin: 0 0 16px 0;
    padding: 0 0 8px 0;
    border-bottom: 1px solid #999; }
  .EditOrganizationsSection .edit-orgs-details-content {
    height: 0;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 100%; }
    .EditOrganizationsSection .edit-orgs-details-content .ms-Grid-col {
      margin-bottom: 16px; }
    .EditOrganizationsSection .edit-orgs-details-content .orgs-list {
      padding: 0 24px; }
      .EditOrganizationsSection .edit-orgs-details-content .orgs-list .bank-action-items {
        display: flex;
        align-items: center;
        justify-content: flex-end; }
    .EditOrganizationsSection .edit-orgs-details-content .add-org-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: 16px; }
    .EditOrganizationsSection .edit-orgs-details-content .no-orgs-listed {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px; }
      .EditOrganizationsSection .edit-orgs-details-content .no-orgs-listed p {
        margin-left: 16px;
        font-size: 1.2rem; }
    .EditOrganizationsSection .edit-orgs-details-content.expanded {
      height: auto;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }
