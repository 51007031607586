.AddEditUser {
  background-color: #fff;
  width: 65%;
  padding: 24px;
  margin: 50px auto; }
  .AddEditUser .add-edit-user-actions {
    margin-top: 157px;
    display: flex;
    align-items: stretch;
    justify-content: flex-end; }
    .AddEditUser .add-edit-user-actions .save-btn {
      margin-left: 16px; }
