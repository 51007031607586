.Reconciliations-wrapper .Reconciliations {
  width: 85%;
  margin: 12px auto;
  padding: 24px 24px 128px;
  background-color: #fff; }
  .Reconciliations-wrapper .Reconciliations .rec-action-btn {
    margin-right: 16px; }
  .Reconciliations-wrapper .Reconciliations .reconciliations-list .fr-reconciliation-row-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    box-sizing: border-box;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #cacaca;
    padding: 8px 16px; }
    .Reconciliations-wrapper .Reconciliations .reconciliations-list .fr-reconciliation-row-item .rec-details {
      margin: 0;
      padding: 8px 0 0 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      width: 100%;
      flex-wrap: wrap; }
      .Reconciliations-wrapper .Reconciliations .reconciliations-list .fr-reconciliation-row-item .rec-details h3 {
        color: #919191;
        padding: 0;
        margin: 0 0 16px 0; }
      .Reconciliations-wrapper .Reconciliations .reconciliations-list .fr-reconciliation-row-item .rec-details .balance {
        font-size: 1.5rem;
        margin: 0;
        padding: 0; }
      .Reconciliations-wrapper .Reconciliations .reconciliations-list .fr-reconciliation-row-item .rec-details .rec-info {
        display: flex;
        box-sizing: border-box;
        flex-wrap: wrap; }
        .Reconciliations-wrapper .Reconciliations .reconciliations-list .fr-reconciliation-row-item .rec-details .rec-info .starting-info {
          flex: 1; }
        .Reconciliations-wrapper .Reconciliations .reconciliations-list .fr-reconciliation-row-item .rec-details .rec-info .ending-info {
          flex: 1; }
    .Reconciliations-wrapper .Reconciliations .reconciliations-list .fr-reconciliation-row-item .rec-row-actions {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%; }
  .Reconciliations-wrapper .Reconciliations .ms-Grid-row {
    margin-top: 32px; }
  .Reconciliations-wrapper .Reconciliations .no-reconciliations {
    display: flex;
    justify-content: center;
    align-items: center; }
    .Reconciliations-wrapper .Reconciliations .no-reconciliations h2 {
      margin-left: 32px; }

@media screen and (max-width: 880px) {
  .Reconciliations-wrapper .Reconciliations {
    margin-top: 0;
    width: 100%; } }
