.Earmarks {
  margin: 15px auto auto;
  padding: 24px;
  background-color: #fff;
  width: 85%; }

.Earmarks .section {
  padding-top: 24px; }

.Earmarks .file-input {
  padding-right: 15px; }

.Earmarks .chosen-contact {
  border-radius: 15px;
  background-color: #e5e5e5;
  display: flex;
  align-items: center;
  height: 24px; }
