@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700);
h2.DataLabel {
  font-weight: 100;
  font-size: 0.9rem;
  display: -webkit-flex;
  display: flex; }
  h2.DataLabel span {
    display: inline-block; }
  h2.DataLabel .label {
    font-weight: 300; }

h3.DataLabel {
  font-weight: 100;
  font-size: 0.7rem;
  display: -webkit-flex;
  display: flex; }
  h3.DataLabel span {
    display: inline-block; }
  h3.DataLabel .label {
    font-weight: 300; }

h4.DataLabel {
  font-weight: 100;
  font-size: 0.8rem;
  display: -webkit-flex;
  display: flex; }
  h4.DataLabel span {
    display: inline-block; }
  h4.DataLabel .label {
    font-weight: 300; }

.LabelGroup {
  display: block;
  box-sizing: border-box; }
  .LabelGroup label {
    box-sizing: border-box; }
    .LabelGroup label .subText {
      box-sizing: border-box;
      margin-left: 12px; }
  .LabelGroup p {
    font-weight: 300;
    box-sizing: border-box;
    padding-left: 18px;
    margin-top: 8px; }
  .LabelGroup.non-numbered label {
    padding-left: 16px; }

.PasswordField {
  position: relative;
  width: 100%;
  display: inline-block; }
  .PasswordField .visibility-button {
    position: absolute;
    top: 29px;
    right: 3px;
    z-index: 999; }
  .PasswordField.underlined .visibility-button {
    top: 2px; }

@-webkit-keyframes slideinfromright {
  from {
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: translate3d(550px, 0, 0);
            transform: translate3d(550px, 0, 0); }
  to {
    -webkit-transform: translate3d(15px, 0, 0);
            transform: translate3d(15px, 0, 0); } }

@keyframes slideinfromright {
  from {
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: translate3d(550px, 0, 0);
            transform: translate3d(550px, 0, 0); }
  to {
    -webkit-transform: translate3d(15px, 0, 0);
            transform: translate3d(15px, 0, 0); } }

@-webkit-keyframes slideinfromleft {
  from {
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: translate3d(-550px, 0, 0);
            transform: translate3d(-550px, 0, 0); }
  to {
    -webkit-transform: translate3d(15px, 0, 0);
            transform: translate3d(15px, 0, 0); } }

@keyframes slideinfromleft {
  from {
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: translate3d(-550px, 0, 0);
            transform: translate3d(-550px, 0, 0); }
  to {
    -webkit-transform: translate3d(15px, 0, 0);
            transform: translate3d(15px, 0, 0); } }

@-webkit-keyframes slideinfromtop {
  from {
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: translate3d(0, -550px, 0);
            transform: translate3d(0, -550px, 0); }
  to {
    -webkit-transform: translate3d(0, 15px, 0);
            transform: translate3d(0, 15px, 0); } }

@keyframes slideinfromtop {
  from {
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: translate3d(0, -550px, 0);
            transform: translate3d(0, -550px, 0); }
  to {
    -webkit-transform: translate3d(0, 15px, 0);
            transform: translate3d(0, 15px, 0); } }

@-webkit-keyframes slideinfrombottom {
  from {
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: translate3d(0, 550px, 0);
            transform: translate3d(0, 550px, 0); }
  to {
    -webkit-transform: translate3d(0, 15px, 0);
            transform: translate3d(0, 15px, 0); } }

@keyframes slideinfrombottom {
  from {
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: translate3d(0, 550px, 0);
            transform: translate3d(0, 550px, 0); }
  to {
    -webkit-transform: translate3d(0, 15px, 0);
            transform: translate3d(0, 15px, 0); } }

.Toast {
  border-radius: 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  position: absolute;
  background-color: #fff;
  height: auto;
  width: 300px;
  padding: 16px 24px 16px 16px;
  z-index: 10000000;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  color: #fff; }
  .Toast.top-right {
    top: 15px;
    right: 15px;
    -webkit-animation: slideinfromright 500ms var(--animation-curve) 0ms;
            animation: slideinfromright 500ms var(--animation-curve) 0ms; }
  .Toast.top-left {
    top: 15px;
    left: 15px;
    -webkit-animation: slideinfromleft 500ms var(--animation-curve) 0ms;
            animation: slideinfromleft 500ms var(--animation-curve) 0ms; }
  .Toast.top-center {
    top: 15px;
    left: calc(50% - 150px);
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-animation: slideinfromtop 500ms var(--animation-curve) 0ms;
            animation: slideinfromtop 500ms var(--animation-curve) 0ms; }
  .Toast.bottom-right {
    bottom: 15px;
    right: 15px;
    -webkit-animation: slideinfromright 500ms var(--animation-curve) 0ms;
            animation: slideinfromright 500ms var(--animation-curve) 0ms; }
  .Toast.bottom-left {
    bottom: 15px;
    left: 15px;
    -webkit-animation: slideinfromleft 500ms var(--animation-curve) 0ms;
            animation: slideinfromleft 500ms var(--animation-curve) 0ms; }
  .Toast.bottom-center {
    bottom: 25px;
    left: calc(50% - 150px);
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-animation: slideinfrombottom 500ms var(--animation-curve) 0ms;
            animation: slideinfrombottom 500ms var(--animation-curve) 0ms; }
  .Toast.INFO {
    background-color: var(--info-color); }
  .Toast.SUCCESS {
    background-color: var(--success-color); }
  .Toast.ERROR {
    background-color: var(--error-color); }
  .Toast.WARNING {
    background-color: var(--warning-color); }
  .Toast p {
    margin-left: 16px; }

.Login {
  background-color: #fff;
  margin: 60px auto 0;
  padding: 24px;
  max-width: 600px;
  box-sizing: border-box; }
  .Login .logo {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 32px; }
  @media screen and (max-width: 639px) {
    .Login {
      width: 98vw;
      position: absolute;
      margin: 0;
      height: 94vh;
      left: 1vw;
      top: 1vh;
      bottom: 1vh;
      max-width: none; } }
  .Login .ms-Grid-row .ms-Grid-col {
    margin-bottom: 16px; }
  .Login .ms-Grid-row .ms-TextField-wrapper .ms-TextField-fieldGroup {
    background: #fff; }
  .Login .links {
    padding-top: 24px; }
    .Login .links span {
      margin: 0 8px; }

.Loading {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 45;
  background-color: rgba(230, 230, 230, 0.9);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }
  .Loading .wrapper {
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.8);
    width: 150px;
    margin: 20% auto 0;
    position: relative;
    padding: 48px;
    height: 150px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
    .Loading .wrapper .ms-Spinner-circle {
      height: 64px;
      width: 64px;
      border-width: 7.5px; }

.FR-DatePicker{position:relative}

.ContactPicker .selected-contact-container{width:100%;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center}

.AggregatesGraph {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin-top: 0;
  width: 100%;
  padding-left: 8px; }
  .AggregatesGraph .aggregates-header {
    margin: 0 0 16px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%; }
    .AggregatesGraph .aggregates-header h2 {
      font-size: 16px;
      margin-left: 16px;
      box-sizing: border-box;
      margin-top: 6px;
      margin-bottom: 0; }
  .AggregatesGraph .aggregate-list {
    width: 98%; }
  .AggregatesGraph .AggregateRow {
    box-sizing: border-box;
    padding: 4px 0 6px 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    border-top: 1px solid rgba(25, 25, 25, 0.05); }
    .AggregatesGraph .AggregateRow:first-of-type {
      border-top: none; }
    .AggregatesGraph .AggregateRow h4 {
      min-width: 130px;
      box-sizing: border-box;
      margin: 0; }
    .AggregatesGraph .AggregateRow span {
      font-weight: bold;
      font-size: 14px;
      text-align: right;
      min-width: 75px;
      margin: 0;
      padding: 0;
      box-sizing: border-box; }

.ContributionForm {
  width: 85%;
  background-color: #fff;
  margin: 30px auto 256px;
  padding: 24px; }
  .ContributionForm .ms-Grid-col {
    margin-bottom: 16px; }
  .ContributionForm .payment-type-section {
    padding: 8px 24px;
    background-color: #f3f3f3; }
  .ContributionForm .contribution-actions-wrapper {
    position: fixed;
    background-color: #fff;
    bottom: -3px;
    z-index: 1;
    left: 0;
    right: 0;
    padding: 16px;
    box-shadow: 0 -1px 8px -1px rgba(0, 0, 0, 0.83); }
    .ContributionForm .contribution-actions-wrapper .actions {
      width: 85%;
      margin: 0 auto;
      text-align: right;
      -webkit-flex-grow: 1;
              flex-grow: 1; }
      .ContributionForm .contribution-actions-wrapper .actions .contribution-btn {
        margin-left: 16px; }
  @media screen and (max-width: 929px) {
    .ContributionForm {
      width: 100%; } }

.AddReportDialog {
  width: 55%;
  min-width: 55% !important;
  max-width: 55% !important;
  overflow: hidden !important; }
  .AddReportDialog > div:first-of-type {
    width: 100%; }
  .AddReportDialog .ms-Dialog-inner {
    overflow: hidden !important;
    width: auto; }
    .AddReportDialog .ms-Dialog-inner .add-disclosure-report {
      padding: 0 24px 64px;
      position: relative;
      overflow: auto;
      width: auto; }
  .AddReportDialog .campaign-maintainer {
    padding: 8px;
    background-color: #f3f3f3; }
  .AddReportDialog .add-report-actions {
    padding-top: 16px;
    border-top: 1px solid #999;
    height: auto;
    width: 100%;
    text-align: right; }
  @media screen and (max-width: 800px) {
    .AddReportDialog {
      width: 100%;
      min-width: 100% !important;
      max-width: 100% !important;
      height: 79vh; } }
  @media screen and (min-width: 801px) and (max-width: 1160px) {
    .AddReportDialog {
      width: 85%;
      min-width: 85% !important;
      max-width: 85% !important; } }

.AddResponsiblePartyDialog {
  width: 55%;
  min-width: 55% !important;
  max-width: 55% !important;
  overflow: hidden !important; }
  .AddResponsiblePartyDialog > div:first-of-type {
    width: 100%; }
  .AddResponsiblePartyDialog .ms-Dialog-inner {
    overflow: hidden !important;
    width: auto; }
    .AddResponsiblePartyDialog .ms-Dialog-inner .add-responsible-party {
      padding: 24px 24px 64px;
      position: relative;
      height: 300px;
      overflow: auto;
      width: auto; }
  .AddResponsiblePartyDialog .footer {
    padding-top: 16px;
    border-top: 1px solid #999;
    height: auto;
    width: 100%;
    text-align: right; }

.ChangePasswordDialog .change-password .ms-Grid-col {
  margin-bottom: 16px; }

.ChangePasswordDialog .change-password .actions {
  margin-top: 32px; }

.GenericSpinnerMessageDialog {
  max-width: 600px; }
  .GenericSpinnerMessageDialog .finished-list-messages {
    max-height: 300px;
    overflow: auto; }
  .GenericSpinnerMessageDialog .finished-instruction-div {
    margin-top: 18px; }

.SubmitReportDialog .submit-spinner {
  margin-top: 10px; }

.SubmitReportDialog .fec-with-warnings-checkbox {
  display: block;
  margin-top: 10px; }

.LongRunningServiceDialog .file-submit-spinner {
  margin-left: 10px; }

.LongRunningServiceDialog .fec-with-warnings-checkbox {
  display: block;
  margin-top: 10px; }

.LongRunningServiceDialog .info {
  color: #4d4d4d; }

.LongRunningServiceDialog .success {
  color: #099101; }

.LongRunningServiceDialog .error {
  color: #a80000; }

.TermsAndConditionsDialog {
  width: 960px;
  min-width: 375px;
  max-width: 960px !important;
  height: 64vh;
  overflow: hidden !important; }
  @media screen and (max-width: 500px) {
    .TermsAndConditionsDialog {
      height: 100vh; } }
  .TermsAndConditionsDialog .ms-Dialog-inner {
    overflow: hidden !important; }
    .TermsAndConditionsDialog .ms-Dialog-inner .terms-and-conditions {
      padding-bottom: 24px;
      position: relative;
      height: 250px;
      overflow: auto; }
    .TermsAndConditionsDialog .ms-Dialog-inner .footer {
      padding-top: 16px;
      border-top: 1px solid #999;
      height: auto;
      width: 100%;
      text-align: right; }
      .TermsAndConditionsDialog .ms-Dialog-inner .footer .cancel-btn {
        margin-right: 8px; }

.GenericSpinnerMessageDialog {
  max-width: 600px; }
  .GenericSpinnerMessageDialog .submit-spinner {
    margin-bottom: 18px; }
  .GenericSpinnerMessageDialog .finished-list-messages {
    max-height: 300px;
    overflow: auto; }
  .GenericSpinnerMessageDialog .finished-instruction-div {
    margin-top: 18px; }

.AddEditDeposit {
  width: 100%;
  height: auto;
  margin-top: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .AddEditDeposit .section-loading {
    height: calc(100vh - 164px);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .AddEditDeposit .options-panel {
    width: 300px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px 32px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    border-right: 1px solid #dadada;
    position: absolute;
    height: calc(100vh - 164px);
    overflow-y: auto; }
    .AddEditDeposit .options-panel .deposit-field {
      margin-bottom: 16px; }
    .AddEditDeposit .options-panel .deposit-actions {
      text-align: center;
      margin-top: 32px; }
  .AddEditDeposit .deposits-list {
    left: 300px;
    right: 0;
    box-sizing: border-box;
    background-color: #fff;
    height: calc(100vh - 164px);
    position: absolute; }
    .AddEditDeposit .deposits-list .search-actions {
      display: -webkit-flex;
      display: flex;
      box-sizing: border-box;
      -webkit-justify-content: flex-start;
              justify-content: flex-start;
      -webkit-align-items: center;
              align-items: center;
      padding-left: 16px; }
      .AddEditDeposit .deposits-list .search-actions .search-box {
        margin-right: 32px; }
      .AddEditDeposit .deposits-list .search-actions .cc-filter {
        margin: 26px 0 0 12px; }
    .AddEditDeposit .deposits-list .select-all-checkbox {
      position: absolute;
      z-index: 99;
      left: 11px;
      top: 116px; }
  @media screen and (max-width: 799px) {
    .AddEditDeposit .options-panel {
      width: 250px; }
    .AddEditDeposit .deposits-list {
      left: 250px;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: flex-start;
              align-items: flex-start; } }
  @media screen and (min-width: 640px) and (max-width: 656px) {
    .AddEditDeposit .deposits-list .select-all-checkbox {
      top: 144px; } }
  @media screen and (max-width: 639px) {
    .AddEditDeposit .deposits-list {
      left: 0; }
      .AddEditDeposit .deposits-list .select-all-checkbox {
        top: 192px; }
      .AddEditDeposit .deposits-list .search-actions-sm {
        box-sizing: border-box;
        padding: 4px 8px; }
        .AddEditDeposit .deposits-list .search-actions-sm .cc-filter {
          margin-top: 26px; } }
  @media screen and (max-width: 479px) {
    .AddEditDeposit .deposits-list {
      left: 0; }
      .AddEditDeposit .deposits-list .select-all-checkbox {
        top: 244px; }
      .AddEditDeposit .deposits-list .search-actions-sm {
        box-sizing: border-box;
        padding: 4px 8px; }
        .AddEditDeposit .deposits-list .search-actions-sm .cc-filter {
          margin-top: 26px; } }

.ExpenditureForm {
  width: 85%;
  background-color: #fff;
  margin: 30px auto 256px;
  padding: 24px; }
  .ExpenditureForm .ms-Grid-col {
    margin-bottom: 16px; }
  .ExpenditureForm .payment-type-section {
    padding: 8px 24px;
    background-color: #f3f3f3; }
  .ExpenditureForm .expenditure-actions-wrapper {
    position: fixed;
    background-color: #fff;
    bottom: -3px;
    z-index: 1;
    left: 0;
    right: 0;
    padding: 16px;
    box-shadow: 0 -1px 8px -1px rgba(0, 0, 0, 0.83); }
    .ExpenditureForm .expenditure-actions-wrapper .actions {
      width: 85%;
      margin: 0 auto;
      text-align: right; }
      .ExpenditureForm .expenditure-actions-wrapper .actions .expenditure-btn {
        margin-left: 16px; }
  .ExpenditureForm .recipient {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-bottom: 1px solid #fff;
    color: #fff;
    background-color: rgba(0, 120, 212, 0.7);
    padding: 0 16px; }
  .ExpenditureForm .chosen-contact {
    border-radius: 15px;
    background-color: #e5e5e5;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    height: 24px; }
  @media screen and (max-width: 929px) {
    .ExpenditureForm {
      width: 100%; } }

.create-new-depository-account-wrapper {
  border-top: 1px solid #919191;
  padding-top: 8px;
  margin-top: 8px;
  box-sizing: border-box; }

.selected-depository-account {
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 16px; }
  .selected-depository-account .selected-depository-account-actions {
    display: -webkit-flex;
    display: flex;
    width: 75%;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center; }

.edit-selected-depository-account-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 16px; }

.LoanForm {
  width: 85%;
  background-color: #fff;
  margin: 30px auto 256px;
  padding: 24px; }
  @media screen and (max-width: 1024px) {
    .LoanForm {
      width: 100%;
      margin-top: 0;
      padding: 8px; } }
  @media screen and (min-width: 1025px) and (max-width: 1920px) {
    .LoanForm {
      width: 95%; } }
  .LoanForm .ms-Grid-col {
    margin-bottom: 16px; }
  .LoanForm .payment-type-section {
    padding: 8px 24px;
    background-color: #f3f3f3; }
  .LoanForm .individual-federal-loan .is-secured {
    display: -webkit-flex;
    display: flex; }
  .LoanForm .business-federal-loan .restructured {
    background-color: #91919122;
    padding-bottom: 8px;
    padding-left: 24px; }
    .LoanForm .business-federal-loan .restructured:last-of-type {
      padding-bottom: 9px; }
  .LoanForm .business-federal-loan .location-of-account {
    background-color: #91919122;
    padding-bottom: 8px;
    padding-left: 24px; }
  .LoanForm .loan-actions-wrapper {
    position: fixed;
    background-color: #fff;
    bottom: -3px;
    z-index: 1;
    left: 0;
    right: 0;
    padding: 16px;
    box-shadow: 0 -1px 8px -1px rgba(0, 0, 0, 0.83); }
    .LoanForm .loan-actions-wrapper .actions {
      width: 85%;
      margin: 0 auto;
      text-align: right;
      -webkit-flex-grow: 1;
              flex-grow: 1; }
      .LoanForm .loan-actions-wrapper .actions .loan-btn {
        margin-left: 16px; }
  .LoanForm .add-responsible-party {
    margin-top: 32px; }
  .LoanForm .responsible-party {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-bottom: 1px solid #fff;
    color: #fff;
    background-color: rgba(0, 120, 212, 0.7);
    padding: 0 16px; }


.AddReconciliations-wrapper {
  width: 100%;
  height: auto;
  margin-top: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
          align-items: stretch;
  background-color: #fff; }
  .AddReconciliations-wrapper .options-panel {
    width: 300px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px 32px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    border-right: 1px solid #dadada;
    position: absolute;
    height: calc(100vh - 164px);
    overflow-y: auto; }
    .AddReconciliations-wrapper .options-panel .rec-field {
      margin-bottom: 16px; }
  .AddReconciliations-wrapper .reconciliation-actions {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding-bottom: 24px; }
    .AddReconciliations-wrapper .reconciliation-actions .selected-deposits {
      color: green; }
    .AddReconciliations-wrapper .reconciliation-actions .selected-expenditures {
      color: red; }
    .AddReconciliations-wrapper .reconciliation-actions label {
      -webkit-align-self: flex-start;
              align-self: flex-start;
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      width: 100%;
      margin-bottom: 4px; }
      .AddReconciliations-wrapper .reconciliation-actions label span {
        margin-left: 16px;
        color: #333;
        font-weight: bold; }
      .AddReconciliations-wrapper .reconciliation-actions label:last-of-type {
        margin-bottom: 20px;
        border-bottom: 1px solid #f3f3f3;
        padding-bottom: 4px; }
  .AddReconciliations-wrapper .select-all-checkbox {
    position: absolute;
    top: 118px;
    left: 12px;
    z-index: 99; }
  .AddReconciliations-wrapper.ms-hiddenSm .reconciliations-list {
    left: 300px;
    right: 0px;
    box-sizing: border-box;
    background-color: #fff;
    position: absolute;
    height: calc(100vh - 158px); }
    .AddReconciliations-wrapper.ms-hiddenSm .reconciliations-list .list-wrapper {
      padding-right: 24px; }
    .AddReconciliations-wrapper.ms-hiddenSm .reconciliations-list .search-actions {
      margin-top: 8px;
      display: -webkit-flex;
      display: flex;
      box-sizing: border-box;
      -webkit-justify-content: flex-start;
              justify-content: flex-start;
      -webkit-align-items: center;
              align-items: center;
      padding-left: 16px;
      width: 100%; }
      .AddReconciliations-wrapper.ms-hiddenSm .reconciliations-list .search-actions .search-box {
        margin-right: 16px;
        width: 50%; }
  .AddReconciliations-wrapper .out-of-balance-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    box-sizing: border-box;
    padding: 8px; }
  .AddReconciliations-wrapper.ms-hiddenMdUp {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 0 16px 44px;
    box-sizing: border-box; }
    .AddReconciliations-wrapper.ms-hiddenMdUp .reconciliation-actions {
      margin-top: 32px; }
    .AddReconciliations-wrapper.ms-hiddenMdUp .reconciliations-list {
      left: 0px;
      right: 0px;
      box-sizing: border-box;
      background-color: #fff;
      position: absolute;
      top: 260px;
      bottom: 60px; }
      .AddReconciliations-wrapper.ms-hiddenMdUp .reconciliations-list .list-wrapper {
        padding-right: 24px; }
      .AddReconciliations-wrapper.ms-hiddenMdUp .reconciliations-list .search-actions {
        margin-top: 8px;
        display: -webkit-flex;
        display: flex;
        box-sizing: border-box;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        -webkit-align-items: center;
                align-items: center;
        padding-left: 16px;
        width: 100%; }
        .AddReconciliations-wrapper.ms-hiddenMdUp .reconciliations-list .search-actions .search-box {
          margin-right: 16px;
          width: 50%; }

.AddEditUser {
  background-color: #fff;
  width: 65%;
  padding: 24px;
  margin: 50px auto; }
  .AddEditUser .add-edit-user-actions {
    margin-top: 157px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: stretch;
            align-items: stretch;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
    .AddEditUser .add-edit-user-actions .save-btn {
      margin-left: 16px; }

.contact-row{display:-webkit-flex;display:flex;-webkit-justify-content:flex-start;justify-content:flex-start;-webkit-align-items:center;align-items:center;padding:4px 8px 12px;border-bottom:1px solid #f3f3f3;margin-bottom:2px;box-sizing:border-box}.contact-row:hover{background-color:#c7e0f4;transition:all ease-in-out 155ms}.contact-row.selected{background-color:#c7e0f4}.contact-row>div:not(.checkbox-row){-webkit-flex:1 1;flex:1 1}.contact-row .contact-details{margin-left:32px;box-sizing:border-box;display:-webkit-flex;display:flex;-webkit-align-items:stretch;align-items:stretch}.contact-row .contact-details h4{font-weight:400;font-size:1.25em;margin:0;padding:0}.contact-row .contact-details address p{margin:0 2px;padding:0;font-style:normal;font-weight:300}.contact-row .contact-details .name-address{min-width:200px}.contact-row .contact-details .phone-email{margin-left:32px}

.AnedotContributions {
  width: 100%;
  padding: 16px 24px;
  margin: 0px auto 45px;
  box-sizing: border-box;
  background-color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  .AnedotContributions .DataLabel {
    text-transform: capitalize; }
  .AnedotContributions .chosen-contact {
    border-radius: 15px;
    background-color: #e5e5e5;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    height: 24px; }
  .AnedotContributions .contribution-wrapper {
    box-sizing: border-box;
    -webkit-flex: 1 1;
            flex: 1 1;
    background-color: #91919111;
    margin-right: 16px;
    padding: 0px 24px 16px 24px; }
    .AnedotContributions .contribution-wrapper.show-deleted {
      background-color: #a8000011; }
    .AnedotContributions .contribution-wrapper h3 {
      margin-bottom: 0; }
    .AnedotContributions .contribution-wrapper .navigation {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      font-size: 12px; }
    .AnedotContributions .contribution-wrapper address {
      font-style: normal;
      box-sizing: border-box; }
      .AnedotContributions .contribution-wrapper address p {
        box-sizing: border-box;
        margin: 4px 0;
        font-style: normal; }
    .AnedotContributions .contribution-wrapper .anedot-contribution-actions {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      -webkit-align-items: center;
              align-items: center;
      width: 100%;
      margin-top: 24px; }
  .AnedotContributions .campaign-details .split-actions {
    margin-top: 24px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
  .AnedotContributions .contact-matching-wrapper {
    box-sizing: border-box;
    -webkit-flex: 3 1;
            flex: 3 1;
    padding: 0 24px 16px;
    width: 100%; }
  .AnedotContributions .possible-contact-actions {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%; }
    .AnedotContributions .possible-contact-actions .ms-ChoiceField-wrapper {
      width: 100%; }
    .AnedotContributions .possible-contact-actions .use-existing {
      width: 100%; }
      .AnedotContributions .possible-contact-actions .use-existing .existing-list {
        margin-top: 16px;
        width: 100%; }
        .AnedotContributions .possible-contact-actions .use-existing .existing-list .contact-row {
          width: 100%; }
  .AnedotContributions .search-contacts {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
  .AnedotContributions .newContact {
    margin-top: 16px; }

.no-anedot-transactions {
  width: 65%;
  box-sizing: border-box;
  width: 65%;
  padding: 24px;
  margin: 15px auto 45px;
  background-color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .no-anedot-transactions .message-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .no-anedot-transactions p {
    font-size: 18px;
    margin-left: 24px; }

.CashOnHand {
  margin: 15px auto auto;
  padding: 24px;
  background-color: #fff;
  width: 85%; }
  .CashOnHand .filed-reports {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin: 8px;
    box-sizing: border-box;
    padding: 8px 16px 24px;
    background-color: #fff; }
  .CashOnHand .report-type-original {
    background-color: rgba(0, 120, 212, 0.7);
    border-radius: 8px;
    color: white;
    padding: 4px 6px; }
    .CashOnHand .report-type-original.final {
      background-color: rgba(168, 0, 0, 0.7); }
  .CashOnHand .report-type-amended {
    background-color: var(--warning-color);
    border-radius: 8px;
    color: white;
    padding: 4px 6px; }
  .CashOnHand .report-type-TBD, .CashOnHand .report-type-48HourNotice {
    background-color: #107c1088;
    border-radius: 8px;
    color: white;
    padding: 4px 6px; }

.ContactTransactions {
  width: 100%; }

.ContactDetails {
  background-color: #fff; }
  .ContactDetails .contact-details-wrapper {
    margin: 8px 4px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-height: 250px; }
    .ContactDetails .contact-details-wrapper.source-contact {
      border: 1px solid rgba(44, 44, 44, 0.2);
      padding: 16px; }
    .ContactDetails .contact-details-wrapper.target-contact {
      border: 1px solid var(--info-color);
      padding: 16px; }
    .ContactDetails .contact-details-wrapper .contact-label {
      padding: 8px;
      margin: 4px 0; }
      .ContactDetails .contact-details-wrapper .contact-label:nth-child(odd) {
        background-color: #f6f6f6; }
  .ContactDetails .merge-actions {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
    .ContactDetails .merge-actions .cancel-btn {
      margin-right: 16px; }

.MergeContactsPanel {
  width: 85%;
  margin: 0 auto; }
  .MergeContactsPanel .source-contacts {
    background-color: #f3f3f3;
    border: 1px solid rgba(0, 0, 0, 0.2); }

.MergeOverlay {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .MergeOverlay .merge-warning {
    margin-top: 15%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
    .MergeOverlay .merge-warning p {
      color: #fff;
      font-size: 2.5rem; }
    .MergeOverlay .merge-warning .merge-warning-actions .cancel-btn {
      margin-right: 16px; }

.columns-filter,
.filters-filter {
  margin-top: 32px; }

.filter-subset {
  border: 1px solid rgba(55, 55, 55, 0.3);
  padding: 8px;
  margin-bottom: 8px;
  background-color: #deecf9; }
  .filter-subset h4 {
    margin-top: 8px;
    padding: 0; }

.Contacts {
  background-color: #fff;
  width: 100%;
  margin: 0px auto 85px;
  padding: 16px 24px;
  box-sizing: border-box;
  position: relative;
  padding-left: 10%;
  padding-right: 10%;
  min-height: 600px; }
  .Contacts .fixed-search {
    position: fixed;
    top: 100px;
    background-color: #FFF;
    z-index: 20;
    border-bottom: 1px solid lightgrey;
    width: inherit;
    box-sizing: border-box;
    width: 79%;
    padding-bottom: 24px; }
  .Contacts header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  .Contacts .contacts-content .empty-contacts-view {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 32px;
    box-sizing: border-box; }
    .Contacts .contacts-content .empty-contacts-view > div {
      margin-left: 16px;
      box-sizing: border-box; }
  .Contacts .contacts-content .contacts-table {
    margin-top: 8px;
    box-sizing: border-box;
    border-top: 1px solid #999; }
  @media screen and (max-width: 1023px) {
    .Contacts {
      width: 100%; }
      .Contacts .contacts-content .contacts-table .contact-row .contact-details {
        margin-left: 8px;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap; }
        .Contacts .contacts-content .contacts-table .contact-row .contact-details .name-address {
          min-width: none; }
        .Contacts .contacts-content .contacts-table .contact-row .contact-details .phone-email {
          margin-left: 0; } }

.ContactDetailsPanel .ContactForm {
  padding-bottom: 220px; }
  .ContactDetailsPanel .ContactForm .chosen-household {
    border-radius: 15px;
    background-color: #f3f3f3;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 8px;
    height: 24px; }
  .ContactDetailsPanel .ContactForm .ms-Grid-row {
    margin-bottom: 4px; }
  .ContactDetailsPanel .ContactForm .additional-field {
    background-color: #f3f3f3;
    padding: 4px 16px; }
  .ContactDetailsPanel .ContactForm .contact-details-actions {
    margin-top: 48px; }
    .ContactDetailsPanel .ContactForm .contact-details-actions .delete-btn {
      background-color: var(--error-color);
      color: '#fff'; }

.selected-contact-actions {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 61px; }
  .selected-contact-actions.hide {
    pointer-events: none; }
  .selected-contact-actions.show {
    pointer-events: all; }
  .selected-contact-actions .actions-inner {
    width: 85%;
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    .selected-contact-actions .actions-inner.show {
      border: 1px solid rgba(55, 55, 55, 0.5);
      background-color: var(--info-color);
      box-sizing: border-box;
      height: 85px;
      padding: 16px 32px;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
      transition: all 350ms var(--animation-curve);
      box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.16), 0 0 6px rgba(0, 0, 0, 0.23); }
    .selected-contact-actions .actions-inner.hide {
      -webkit-transform: translate3d(0, 125px, 0);
              transform: translate3d(0, 125px, 0);
      transition: all 350ms var(--animation-curve); }
    .selected-contact-actions .actions-inner h3 {
      color: white;
      font-weight: 400; }

.SpecificList {
  width: 100%;
  height: auto;
  margin-top: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .SpecificList .options-panel {
    width: 300px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px 32px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    border-right: 1px solid #dadada;
    position: absolute;
    height: calc(100vh - 164px);
    overflow-x: hidden; }
  .SpecificList .list {
    left: 300px;
    right: 0;
    box-sizing: border-box;
    background-color: #fff;
    height: calc(100vh - 164px);
    position: absolute; }
    .SpecificList .list.loading-csv {
      padding-top: 165px; }
    .SpecificList .list .loading-list {
      padding-top: 1px;
      width: 100%;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      margin-top: -300px; }

.ContactMerge {
  margin: 15px auto auto;
  padding: 24px;
  background-color: #fff;
  width: 85%;
  min-height: 600px; }
  .ContactMerge .contact-merge-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 16px; }
  .ContactMerge .merge-header-row {
    font-weight: bold; }
  .ContactMerge .is-pinned {
    background-color: #DDDDDD; }
  .ContactMerge .button-div {
    margin-top: 36px; }
  .ContactMerge .back-to-top {
    position: absolute;
    left: 13px;
    bottom: 80px;
    background-color: white;
    overflow: hidden;
    border-radius: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 34px;
    height: 34px;
    box-shadow: 4px 4px 4px -1px rgba(0, 0, 0, 0.22); }
  .ContactMerge .row {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    padding: 2px 6px;
    border-bottom: 1px solid #f3f3f3;
    margin-bottom: 2px;
    box-sizing: border-box; }
  .ContactMerge .row-item {
    -webkit-flex: 1 1;
            flex: 1 1;
    padding: 8px 8px; }
  .ContactMerge .row-item-large {
    -webkit-flex: 2 2;
            flex: 2 2;
    padding: 8px 8px; }
  .ContactMerge .row-item-other {
    -webkit-flex: 1 1;
            flex: 1 1;
    padding: 8px 8px; }
  .ContactMerge .row-item-large-other {
    -webkit-flex: 2 2;
            flex: 2 2;
    padding: 8px 8px; }
  .ContactMerge .row-item:hover {
    cursor: pointer; }
  .ContactMerge .row-item-large:hover {
    cursor: pointer; }
  .ContactMerge .row-check {
    -webkit-flex: 0 0 50px;
            flex: 0 0 50px; }

.EmptyView {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 48px;
  padding-bottom: 64px; }
  .EmptyView h2 {
    margin-left: 32px; }

.ObservableContributionSummary .search-line{display:-webkit-flex;display:flex}.ObservableContributionSummary .search-line .year-picker{margin-right:12px;margin-left:16px}.ObservableContributionSummary .search-line .election-cycle{width:180px}.ObservableContributionSummary .search-line .date-label{margin-top:4px;margin-right:8px;margin-left:16px}.ObservableContributionSummary .no-employee-or-occupation{margin-left:32px;margin-top:6px}.ObservableContributionSummary .contribution-header-icon{font-size:16px}.ObservableContributionSummary .summary-contribution-row{cursor:pointer}.ObservableContributionSummary .item-detail-header{display:-webkit-flex;display:flex;margin-left:48px;margin-right:48px;height:32px;font-weight:600;font-size:12px;color:#323130;border-bottom:1px solid #eaeaea;margin-bottom:6px}.ObservableContributionSummary .item-detail-header .large{-webkit-flex:0 0 200px;flex:0 0 200px;padding:4px 8px}.ObservableContributionSummary .item-detail-header .medium{-webkit-flex:0 0 100px;flex:0 0 100px;padding:4px 8px}.ObservableContributionSummary .item-detail-header .money{-webkit-flex:0 0 100px;flex:0 0 100px;padding:4px 12px 4px 0px;text-align:right}.ObservableContributionSummary .item-detail-row{display:-webkit-flex;display:flex;margin-left:48px;margin-right:48px;height:32px}.ObservableContributionSummary .item-detail-row .large{-webkit-flex:0 0 200px;flex:0 0 200px;padding:4px 8px}.ObservableContributionSummary .item-detail-row .medium{-webkit-flex:0 0 100px;flex:0 0 100px;padding:4px 8px}.ObservableContributionSummary .item-detail-row .money{-webkit-flex:0 0 100px;flex:0 0 100px;padding:4px 12px 4px 0px;text-align:right}

.ContributionLimits {
  margin: 15px auto auto;
  padding: 24px;
  background-color: #fff;
  width: 85%;
  min-height: 600px; }
  .ContributionLimits .contributions-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 16px; }
  .ContributionLimits .back-to-top {
    position: absolute;
    left: 13px;
    bottom: 80px;
    background-color: white;
    overflow: hidden;
    border-radius: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 34px;
    height: 34px;
    box-shadow: 4px 4px 4px -1px rgba(0, 0, 0, 0.22); }

.compact-loan-details {
  padding: 16px; }

.expanded-loan-details {
  padding: 16px; }

.contributionApply {
  margin: 15px auto auto;
  padding: 24px;
  background-color: #fff;
  width: 85%; }

.ContributionUpload {
  margin: 15px auto auto;
  padding: 24px;
  background-color: #fff;
  width: 85%; }

.Deposits-wrapper {
  height: calc(100vh - 125px); }
  .Deposits-wrapper .Deposits {
    background-color: #fff;
    width: 65%;
    height: auto;
    padding: 24px 24px 128px;
    box-sizing: border-box;
    margin: 20px auto 50px; }
    @media screen and (max-width: 959px) {
      .Deposits-wrapper .Deposits {
        width: 100%;
        margin-top: 0;
        padding-bottom: 128px; } }
    @media screen and (max-width: 639px) {
      .Deposits-wrapper .Deposits {
        width: 100%;
        padding: 8px 8px 0;
        overflow: auto; } }
    .Deposits-wrapper .Deposits .ms-Grid-row {
      margin-top: 32px; }
    .Deposits-wrapper .Deposits .no-deposits {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center; }
      .Deposits-wrapper .Deposits .no-deposits h2 {
        margin-left: 32px; }
    .Deposits-wrapper .Deposits .deposit-action-btn {
      margin-right: 16px; }
    .Deposits-wrapper .Deposits .deposits-list .fr-deposit-row-item {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-align-items: flex-start;
              align-items: flex-start;
      box-sizing: border-box;
      border-top: 1px solid #eaeaea;
      border-bottom: 1px solid #cacaca;
      padding: 8px 16px;
      margin-bottom: 0; }
      .Deposits-wrapper .Deposits .deposits-list .fr-deposit-row-item .deposit-details {
        -webkit-flex: 2 1;
                flex: 2 1;
        margin: 0;
        padding: 8px 0 0 16px;
        box-sizing: border-box;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column; }
        .Deposits-wrapper .Deposits .deposits-list .fr-deposit-row-item .deposit-details h3 {
          color: #919191;
          padding: 0;
          margin: 0; }
        .Deposits-wrapper .Deposits .deposits-list .fr-deposit-row-item .deposit-details .amount {
          font-size: 1.8rem;
          margin: 0;
          padding: 0; }
      .Deposits-wrapper .Deposits .deposits-list .fr-deposit-row-item .deposit-row-actions {
        -webkit-flex: 1 1;
                flex: 1 1;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: flex-end;
                align-items: flex-end;
        width: 100%; }
        .Deposits-wrapper .Deposits .deposits-list .fr-deposit-row-item .deposit-row-actions > span {
          font-style: italic;
          color: #616161;
          margin-top: 48px; }
      @media screen and (max-width: 639px) {
        .Deposits-wrapper .Deposits .deposits-list .fr-deposit-row-item {
          -webkit-flex-direction: column;
                  flex-direction: column;
          padding: 4px 8px; }
          .Deposits-wrapper .Deposits .deposits-list .fr-deposit-row-item .deposit-details {
            -webkit-flex: 1 1;
                    flex: 1 1; }
          .Deposits-wrapper .Deposits .deposits-list .fr-deposit-row-item .deposit-row-actions {
            -webkit-flex: 1 1;
                    flex: 1 1;
            -webkit-flex-direction: row-reverse;
                    flex-direction: row-reverse;
            -webkit-align-items: center;
                    align-items: center;
            -webkit-justify-content: space-between;
                    justify-content: space-between; }
            .Deposits-wrapper .Deposits .deposits-list .fr-deposit-row-item .deposit-row-actions > span {
              position: relative;
              bottom: inherit;
              padding-left: 24px;
              margin-top: 0; } }

.deposit-details-panel .details-wrapper {
  position: relative;
  margin-bottom: 24px; }
  @media screen and (min-height: 900px) {
    .deposit-details-panel .details-wrapper {
      height: 400px;
      max-height: 400px; } }

.DeveloperCivixAPI {
  margin: 15px auto auto;
  padding: 24px;
  background-color: #fff;
  width: 85%; }

.CreditCardForm {
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-flex-direction: row;
          flex-direction: row; }
  @media screen and (max-width: 500px) {
    .CreditCardForm {
      -webkit-flex-direction: column;
              flex-direction: column; } }
  .CreditCardForm .pricing {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding: 16px 8px 0;
    color: #013243; }
    .CreditCardForm .pricing h4 {
      font-size: 1.2em;
      color: #013243; }
    .CreditCardForm .pricing h2 {
      color: #013243;
      font-weight: bold;
      font-size: 1.3em; }
    .CreditCardForm .pricing p {
      font-size: 0.8em;
      text-align: justify;
      padding: 0 8px;
      line-height: 14px;
      color: #013243; }
  .CreditCardForm .fields {
    -webkit-flex: 3 1;
            flex: 3 1;
    padding-right: 32px; }
    @media screen and (max-width: 500px) {
      .CreditCardForm .fields {
        padding-right: 16px; } }
    .CreditCardForm .fields .ms-Grid-col {
      margin-top: 16px; }
    .CreditCardForm .fields .stripe-element {
      color: #8a8886 !important;
      box-sizing: border-box;
      box-shadow: none;
      margin: 0;
      display: block;
      word-wrap: break-word;
      overflow-wrap: break-word;
      line-height: 19px;
      font-weight: 600; }
      .CreditCardForm .fields .stripe-element .StripeElement {
        background-color: #fff;
        color: #323130 !important;
        height: 32px;
        border: 1px solid #8a8886;
        padding: 8px;
        box-sizing: border-box;
        border-radius: 2px; }
        .CreditCardForm .fields .stripe-element .StripeElement:focus, .CreditCardForm .fields .stripe-element .StripeElement:active {
          border-width: 2px !important;
          border-color: var(--primary-color) !important; }
        .CreditCardForm .fields .stripe-element .StripeElement:hover {
          border-color: #8a8886; }
      .CreditCardForm .fields .stripe-element:focus, .CreditCardForm .fields .stripe-element:active {
        border-width: 2px !important;
        border-color: var(--primary-color) !important; }

.EditBillingInfo {
  padding: 24px;
  margin: 30px auto 0;
  background-color: #fff;
  width: 85%; }
  .EditBillingInfo .logo {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .EditBillingInfo .logo h3 {
      margin-left: 48px; }
  .EditBillingInfo .update-billing-info-actions {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 24px 32px; }
    .EditBillingInfo .update-billing-info-actions .cancel-btn {
      margin-right: 16px; }

.CancelAccount {
  background-color: #fff;
  margin: 24px auto 500px;
  width: 85%;
  padding: 24px; }
  .CancelAccount .cancel-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
  .CancelAccount .edit-billing-info-actions {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }

.EditFilerDetails {
  background-color: #fff;
  width: 100%;
  height: auto;
  position: relative;
  padding: 24px;
  box-sizing: border-box; }
  .EditFilerDetails h3 {
    margin: 0 0 16px 0;
    padding: 0 0 8px 0;
    border-bottom: 1px solid #999; }
  .EditFilerDetails .edit-filer-details-content {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    height: 0;
    overflow: hidden; }
    .EditFilerDetails .edit-filer-details-content.expanded {
      height: auto;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }
    @media screen and (max-width: 753px) {
      .EditFilerDetails .edit-filer-details-content {
        -webkit-flex-direction: column;
                flex-direction: column; } }
    .EditFilerDetails .edit-filer-details-content .office-info {
      -webkit-flex: 1 1;
              flex: 1 1;
      border: 1px solid #ddd;
      border-bottom: 1px solid #999;
      padding: 16px;
      background-color: #f4f4f4; }
    .EditFilerDetails .edit-filer-details-content .filer-info {
      -webkit-flex: 2 1;
              flex: 2 1;
      padding: 16px 64px; }
      @media screen and (max-width: 753px) {
        .EditFilerDetails .edit-filer-details-content .filer-info {
          padding: 16px; } }
      .EditFilerDetails .edit-filer-details-content .filer-info .ms-Grid-col {
        margin-bottom: 16px; }
      .EditFilerDetails .edit-filer-details-content .filer-info .incumbent-checkbox {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        padding-top: 8px; }

.EditCandidateDetails {
  background-color: #fff;
  width: 100%;
  height: auto;
  position: relative;
  padding: 24px;
  margin-top: 24px;
  box-sizing: border-box; }
  .EditCandidateDetails h3 {
    margin: 0 0 16px 0;
    padding: 0 0 8px 0;
    border-bottom: 1px solid #999; }
  .EditCandidateDetails .edit-candidate-details-content {
    height: 0;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 100%; }
    .EditCandidateDetails .edit-candidate-details-content .ms-Grid-col {
      margin-bottom: 16px; }
    .EditCandidateDetails .edit-candidate-details-content.expanded {
      height: auto;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }

.EditCommitteeDetails {
  background-color: #fff;
  width: 100%;
  height: auto;
  position: relative;
  padding: 24px;
  margin-top: 24px;
  box-sizing: border-box; }
  .EditCommitteeDetails h3 {
    margin: 0 0 16px 0;
    padding: 0 0 8px 0;
    border-bottom: 1px solid #999; }
  .EditCommitteeDetails .edit-committee-details-content {
    height: 0;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 100%; }
    .EditCommitteeDetails .edit-committee-details-content .ms-Grid-col {
      margin-bottom: 16px; }
    .EditCommitteeDetails .edit-committee-details-content.expanded {
      height: auto;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }

.EditChairpersonDetails {
  background-color: #fff;
  width: 100%;
  height: auto;
  position: relative;
  padding: 24px;
  margin-top: 24px;
  box-sizing: border-box; }
  .EditChairpersonDetails h3 {
    margin: 0 0 16px 0;
    padding: 0 0 8px 0;
    border-bottom: 1px solid #999; }
  .EditChairpersonDetails .edit-chairperson-details-content {
    height: 0;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 100%; }
    .EditChairpersonDetails .edit-chairperson-details-content .ms-Grid-col {
      margin-bottom: 16px; }
    .EditChairpersonDetails .edit-chairperson-details-content.expanded {
      height: auto;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }

.EditTreasurerDetails {
  background-color: #fff;
  width: 100%;
  height: auto;
  position: relative;
  padding: 24px;
  margin-top: 24px;
  box-sizing: border-box; }
  .EditTreasurerDetails h3 {
    margin: 0 0 16px 0;
    padding: 0 0 8px 0;
    border-bottom: 1px solid #999; }
  .EditTreasurerDetails .edit-treasurer-details-content {
    height: 0;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 100%; }
    .EditTreasurerDetails .edit-treasurer-details-content .ms-Grid-col {
      margin-bottom: 16px; }
    .EditTreasurerDetails .edit-treasurer-details-content h4 {
      margin: 0;
      padding: 0;
      font-size: 0.9rem;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
    .EditTreasurerDetails .edit-treasurer-details-content p {
      margin: 8px 0; }
    .EditTreasurerDetails .edit-treasurer-details-content address p {
      margin: 2px 0; }
    .EditTreasurerDetails .edit-treasurer-details-content.expanded {
      height: auto;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }

.EditBalanceDetails {
  background-color: #fff;
  width: 100%;
  height: auto;
  position: relative;
  padding: 24px;
  margin-top: 24px;
  box-sizing: border-box; }
  .EditBalanceDetails h3 {
    margin: 0 0 16px 0;
    padding: 0 0 8px 0;
    border-bottom: 1px solid #999; }
  .EditBalanceDetails .edit-balance-details-content {
    height: 0;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 100%; }
    .EditBalanceDetails .edit-balance-details-content .ms-Grid-col {
      margin-bottom: 16px; }
    .EditBalanceDetails .edit-balance-details-content.expanded {
      height: auto;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }

.EditInitialIndebtednessDetails {
  background-color: #fff;
  width: 100%;
  height: auto;
  position: relative;
  padding: 24px;
  margin-top: 24px;
  box-sizing: border-box; }
  .EditInitialIndebtednessDetails h3 {
    margin: 0 0 16px 0;
    padding: 0 0 8px 0;
    border-bottom: 1px solid #999; }
  .EditInitialIndebtednessDetails .edit-indebtedness-details-content {
    height: 0;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 100%; }
    .EditInitialIndebtednessDetails .edit-indebtedness-details-content .ms-Grid-col {
      margin-bottom: 16px; }
    .EditInitialIndebtednessDetails .edit-indebtedness-details-content.expanded {
      height: auto;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }
    .EditInitialIndebtednessDetails .edit-indebtedness-details-content .cancel-btn {
      margin-right: 16px; }
    .EditInitialIndebtednessDetails .edit-indebtedness-details-content .add-debt {
      border-right: 1px solid #999;
      padding: 16px 32px; }
      .EditInitialIndebtednessDetails .edit-indebtedness-details-content .add-debt .debt-actions {
        padding: 32px 8px; }
      @media screen and (max-width: 639px) {
        .EditInitialIndebtednessDetails .edit-indebtedness-details-content .add-debt {
          border: none;
          padding: 8px 16px; }
          .EditInitialIndebtednessDetails .edit-indebtedness-details-content .add-debt .debt-actions {
            padding: 16px 8px; } }
    .EditInitialIndebtednessDetails .edit-indebtedness-details-content .current-debts {
      padding: 16px 32px;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: stretch;
              align-items: stretch; }
      .EditInitialIndebtednessDetails .edit-indebtedness-details-content .current-debts .debt-item {
        padding: 8px 16px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        border: 1px solid #ddd;
        border-bottom: 1px solid #999;
        background-color: #f4f4f4;
        margin: 0 auto; }
        .EditInitialIndebtednessDetails .edit-indebtedness-details-content .current-debts .debt-item h4 {
          font-weight: 600;
          font-size: 1em;
          margin: 0;
          padding: 0; }
        .EditInitialIndebtednessDetails .edit-indebtedness-details-content .current-debts .debt-item p {
          margin: 0; }
        .EditInitialIndebtednessDetails .edit-indebtedness-details-content .current-debts .debt-item > div {
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
                  align-items: center; }
          .EditInitialIndebtednessDetails .edit-indebtedness-details-content .current-debts .debt-item > div:first-of-type {
            -webkit-flex: 2 1;
                    flex: 2 1;
            -webkit-flex-direction: column;
                    flex-direction: column;
            padding-right: 16px; }
          .EditInitialIndebtednessDetails .edit-indebtedness-details-content .current-debts .debt-item > div:last-of-type {
            -webkit-flex: 1 1;
                    flex: 1 1;
            -webkit-justify-content: flex-end;
                    justify-content: flex-end; }
      @media screen and (max-width: 639px) {
        .EditInitialIndebtednessDetails .edit-indebtedness-details-content .current-debts {
          padding: 8px; }
          .EditInitialIndebtednessDetails .edit-indebtedness-details-content .current-debts .debt-item {
            padding: 8px; }
            .EditInitialIndebtednessDetails .edit-indebtedness-details-content .current-debts .debt-item > div:first-of-type {
              padding-right: 0; } }

.EditBanksSection {
  background-color: #fff;
  width: 100%;
  height: auto;
  position: relative;
  padding: 24px;
  margin-top: 24px;
  box-sizing: border-box; }
  .EditBanksSection h3 {
    margin: 0 0 16px 0;
    padding: 0 0 8px 0;
    border-bottom: 1px solid #999; }
  .EditBanksSection .edit-banks-details-content {
    height: 0;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 100%; }
    .EditBanksSection .edit-banks-details-content .ms-Grid-col {
      margin-bottom: 16px; }
    .EditBanksSection .edit-banks-details-content .bank-list {
      padding: 0 24px; }
      .EditBanksSection .edit-banks-details-content .bank-list .bank-action-items {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: flex-end;
                justify-content: flex-end; }
    .EditBanksSection .edit-banks-details-content .add-bank-actions {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      padding-top: 16px; }
    .EditBanksSection .edit-banks-details-content .no-banks-listed {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      padding: 16px; }
      .EditBanksSection .edit-banks-details-content .no-banks-listed p {
        margin-left: 16px;
        font-size: 1.2rem; }
    .EditBanksSection .edit-banks-details-content.expanded {
      height: auto;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }

.EditOrganizationsSection {
  background-color: #fff;
  width: 100%;
  height: auto;
  position: relative;
  padding: 24px;
  margin-top: 24px;
  box-sizing: border-box; }
  .EditOrganizationsSection h3 {
    margin: 0 0 16px 0;
    padding: 0 0 8px 0;
    border-bottom: 1px solid #999; }
  .EditOrganizationsSection .edit-orgs-details-content {
    height: 0;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 100%; }
    .EditOrganizationsSection .edit-orgs-details-content .ms-Grid-col {
      margin-bottom: 16px; }
    .EditOrganizationsSection .edit-orgs-details-content .orgs-list {
      padding: 0 24px; }
      .EditOrganizationsSection .edit-orgs-details-content .orgs-list .bank-action-items {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: flex-end;
                justify-content: flex-end; }
    .EditOrganizationsSection .edit-orgs-details-content .add-org-actions {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      padding-top: 16px; }
    .EditOrganizationsSection .edit-orgs-details-content .no-orgs-listed {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      padding: 16px; }
      .EditOrganizationsSection .edit-orgs-details-content .no-orgs-listed p {
        margin-left: 16px;
        font-size: 1.2rem; }
    .EditOrganizationsSection .edit-orgs-details-content.expanded {
      height: auto;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }

.EditCampaign {
  margin: 0 auto;
  width: 100%;
  height: 81vh; }
  @media screen and (max-width: 639px) {
    .EditCampaign {
      width: 100%; } }
  .EditCampaign .container {
    width: 100%;
    padding: 24px 24px 512px;
    position: relative;
    box-sizing: border-box; }
    .EditCampaign .container h1 {
      font-weight: 300;
      margin: 0;
      padding: 0; }
    .EditCampaign .container .section {
      background-color: #fff; }
  .EditCampaign .action-footer {
    height: 65px;
    position: fixed;
    left: 0;
    right: 0;
    background-color: #f4f4f4;
    bottom: 0;
    border: 1px solid rgba(0, 0, 0, 0.83);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 0 24px;
    box-sizing: border-box;
    z-index: 55; }
    .EditCampaign .action-footer .cancel-btn {
      margin-right: 16px; }

.FECReportEdit {
  width: 95%;
  margin: 20px auto; }
  .FECReportEdit h3 {
    border-bottom: 2px solid rgba(0, 0, 0, 0.87); }
  .FECReportEdit label[class^='ms-Label'] {
    font-weight: 900 !important; }
  .FECReportEdit .section-actions {
    padding: 16px 24px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center; }
  .FECReportEdit .fec-report-section {
    background-color: white;
    padding: 24px;
    margin-bottom: 16px; }
    .FECReportEdit .fec-report-section [class$='-expanded'] {
      display: block; }
    .FECReportEdit .fec-report-section [class$='-collapsed'] {
      display: none; }
    .FECReportEdit .fec-report-section .sub-question {
      padding-left: 32px; }
    .FECReportEdit .fec-report-section .entry-row {
      padding-top: 16px;
      padding-bottom: 16px; }

.BeginningBalanceSourceDialog {
  width: 700px;
  min-width: 700px !important;
  max-width: 700px !important;
  overflow: hidden !important; }
  .BeginningBalanceSourceDialog > div:first-of-type {
    width: 100%; }
  .BeginningBalanceSourceDialog .ms-Dialog-inner {
    overflow: hidden !important;
    width: auto; }
  .BeginningBalanceSourceDialog .footer {
    padding-top: 16px;
    border-top: 1px solid #999;
    height: auto;
    width: 100%;
    text-align: right; }

.warning-box-outer {
  padding: 8px 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .warning-box-outer .warning-actions {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }

.EditReport {
  width: 85%;
  margin: 10px auto 256px; }
  .EditReport .ms-Grid-col {
    margin-bottom: 16px; }
  .EditReport .label-content {
    font-weight: 500; }
  .EditReport .beginning_balances,
  .EditReport .cover-page,
  .EditReport .contributions,
  .EditReport .loans,
  .EditReport .expenditures,
  .EditReport .investments,
  .EditReport .summary,
  .EditReport .indebtedness,
  .EditReport .addendum,
  .EditReport .debts-section {
    background-color: #fff;
    padding: 24px;
    margin-bottom: 16px; }
    .EditReport .beginning_balances header,
    .EditReport .cover-page header,
    .EditReport .contributions header,
    .EditReport .loans header,
    .EditReport .expenditures header,
    .EditReport .investments header,
    .EditReport .summary header,
    .EditReport .indebtedness header,
    .EditReport .addendum header,
    .EditReport .debts-section header {
      cursor: pointer;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
    .EditReport .beginning_balances .content-collapsed,
    .EditReport .cover-page .content-collapsed,
    .EditReport .contributions .content-collapsed,
    .EditReport .loans .content-collapsed,
    .EditReport .expenditures .content-collapsed,
    .EditReport .investments .content-collapsed,
    .EditReport .summary .content-collapsed,
    .EditReport .indebtedness .content-collapsed,
    .EditReport .addendum .content-collapsed,
    .EditReport .debts-section .content-collapsed {
      height: 0;
      overflow: hidden; }
    .EditReport .beginning_balances .content-expanded,
    .EditReport .cover-page .content-expanded,
    .EditReport .contributions .content-expanded,
    .EditReport .loans .content-expanded,
    .EditReport .expenditures .content-expanded,
    .EditReport .investments .content-expanded,
    .EditReport .summary .content-expanded,
    .EditReport .indebtedness .content-expanded,
    .EditReport .addendum .content-expanded,
    .EditReport .debts-section .content-expanded {
      height: auto;
      overflow: auto; }
  .EditReport .form-label {
    font-size: 1.5em;
    margin-top: 5px;
    margin-right: 24px;
    font-weight: 300;
    text-transform: lowercase; }
  .EditReport .money-label {
    min-height: 48px; }
  .EditReport .money-input {
    margin-top: 16px;
    box-sizing: border-box; }
    .EditReport .money-input.with-label {
      margin-top: -16px; }
  .EditReport .section-actions {
    padding-top: 16px;
    text-align: right; }

.edit-report-actions {
  position: fixed;
  background-color: #fff;
  bottom: -3px;
  z-index: 1;
  left: 0;
  right: 0;
  padding: 16px;
  box-shadow: 0 -1px 8px -1px rgba(0, 0, 0, 0.83); }
  .edit-report-actions .actions {
    width: 85%;
    margin: 0 auto;
    text-align: right; }
    .edit-report-actions .actions .action-btn {
      font-size: 0.8em;
      margin-left: 16px; }
      .edit-report-actions .actions .action-btn.delete-btn {
        background-color: var(--error-color);
        color: '#fff'; }
  @media screen and (max-width: 1024px) {
    .edit-report-actions {
      display: -webkit-flex;
      display: flex; }
      .edit-report-actions .action-btn.delete-btn {
        float: none; } }
  @media screen and (max-width: 689px) {
    .edit-report-actions .action-btn {
      margin: 8px 0; } }
  @media screen and (max-width: 479px) {
    .edit-report-actions .action-btn {
      width: 100%; }
      .edit-report-actions .action-btn.delete-btn {
        margin-top: 16px; } }
  .edit-report-actions .ms-DetailsHeader {
    height: 50px; }

.ViewAllReports {
  width: 90%;
  margin: 45px auto 256px;
  box-sizing: border-box; }
  .ViewAllReports .working-reports {
    margin: 8px;
    box-sizing: border-box;
    padding: 8px 16px 24px;
    background-color: #fff; }
  .ViewAllReports .filed-reports {
    margin: 8px;
    box-sizing: border-box;
    padding: 8px 16px 24px;
    background-color: #fff; }
  .ViewAllReports .no-filed-reports {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding-top: 16px;
    margin-top: 32px; }
    .ViewAllReports .no-filed-reports p {
      color: #000000aa;
      font-size: 0.8rem;
      width: 250px;
      margin-left: 16px; }
  .ViewAllReports .report-type-original {
    background-color: rgba(0, 120, 212, 0.7);
    border-radius: 8px;
    color: white;
    padding: 4px 6px; }
    .ViewAllReports .report-type-original.final {
      background-color: rgba(168, 0, 0, 0.7); }
  .ViewAllReports .report-type-amended {
    background-color: var(--warning-color);
    border-radius: 8px;
    color: white;
    padding: 4px 6px; }
  .ViewAllReports .report-type-TBD, .ViewAllReports .report-type-48HourNotice {
    background-color: #107c1088;
    border-radius: 8px;
    color: white;
    padding: 4px 6px; }
  @media screen and (max-width: 875px) {
    .ViewAllReports {
      width: 92%;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: flex-start;
              justify-content: flex-start; }
      .ViewAllReports .working-reports {
        width: 100%; }
      .ViewAllReports .filed-reports {
        width: 100%; } }

.Earmarks {
  margin: 15px auto auto;
  padding: 24px;
  background-color: #fff;
  width: 85%; }

.Earmarks .section {
  padding-top: 24px; }

.Earmarks .file-input {
  padding-right: 15px; }

.Earmarks .chosen-contact {
  border-radius: 15px;
  background-color: #e5e5e5;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 24px; }

.Ledger {
  background-color: #fff;
  width: 89%;
  margin: 45px auto;
  padding: 16px; }
  .Ledger header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between; }

.Lists {
  width: 100%;
  height: auto;
  min-height: 800px;
  margin-top: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .Lists .options-panel {
    width: 300px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px 32px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    border-right: 1px solid #dadada;
    position: absolute;
    height: calc(100vh - 164px);
    overflow-x: hidden; }
    .Lists .options-panel .column {
      box-sizing: border-box;
      padding: 6px 12px 6px 6px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
      .Lists .options-panel .column:hover {
        background-color: rgba(170, 170, 170, 0.3); }
  .Lists .list {
    left: 300px;
    right: 0;
    box-sizing: border-box;
    background-color: #fff;
    height: calc(100vh - 164px);
    position: absolute; }
    .Lists .list.loading-csv {
      padding-top: 165px; }

.ListsIndex {
  width: 90%;
  background-color: #fff;
  padding: 24px;
  margin: 48px auto 0;
  box-sizing: border-box;
  min-height: 600px; }
  .ListsIndex .list-options {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: stretch;
            align-items: stretch; }
    .ListsIndex .list-options .list-option {
      min-height: 150px;
      min-width: 275px;
      margin: 16px 16px 0;
      border: 1px solid rgba(0, 0, 0, 0.12);
      padding: 16px 16px 0; }
      .ListsIndex .list-options .list-option .list-option-header {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        -webkit-flex-direction: row;
                flex-direction: row;
        margin: 0;
        padding: 0 0 24px 0;
        box-sizing: border-box; }
        .ListsIndex .list-options .list-option .list-option-header .help-icon {
          height: 40px; }
        .ListsIndex .list-options .list-option .list-option-header h4 {
          margin: 0 16px 0 0;
          padding: 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
        .ListsIndex .list-options .list-option .list-option-header > i {
          display: -webkit-flex;
          display: flex;
          font-size: 16px;
          color: white;
          background-color: #919191;
          padding: 8px;
          border-radius: 50%;
          -webkit-align-items: center;
                  align-items: center;
          -webkit-justify-content: center;
                  justify-content: center;
          height: 24px;
          width: 24px; }
      .ListsIndex .list-options .list-option .list-option-contents {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: stretch;
                align-items: stretch;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: space-between;
                justify-content: space-between; }
        .ListsIndex .list-options .list-option .list-option-contents > button {
          margin-bottom: 16px; }

.MainDashboard {
  margin: 16px 0;
  padding: 16px;
  font-size: 0.9em; }
  .MainDashboard .dashboard-content {
    display: -webkit-flex;
    display: flex;
    margin: 0 auto;
    width: 98%;
    max-width: 1535px;
    box-sizing: border-box;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    @media screen and (max-width: 639px) {
      .MainDashboard .dashboard-content {
        -webkit-flex-direction: column;
                flex-direction: column; } }
  .MainDashboard h2 {
    font-weight: 400;
    padding: 0 0 8px;
    margin: 0;
    font-size: 0.9rem; }
  .MainDashboard h3 {
    font-size: 0.7rem; }
  .MainDashboard .divider {
    height: 1px;
    background-color: #999; }
  .MainDashboard .campaign-info {
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-order: 1;
            order: 1;
    background-color: #fff;
    padding: 8px 16px;
    margin: 0 16px 0 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: stretch;
            align-items: stretch; }
    @media screen and (max-width: 639px) {
      .MainDashboard .campaign-info {
        margin-right: 0; } }
    .MainDashboard .campaign-info .campaign-logo {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
    .MainDashboard .campaign-info .campaign-details {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-flex-direction: column;
              flex-direction: column;
      height: auto; }
      .MainDashboard .campaign-info .campaign-details h2,
      .MainDashboard .campaign-info .campaign-details h3 {
        text-align: center; }
  .MainDashboard .next-report {
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-order: 3;
            order: 3;
    margin-left: 16px;
    background-color: #fff;
    padding: 24px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }
    @media screen and (max-width: 889px) {
      .MainDashboard .next-report {
        -webkit-order: 2;
                order: 2;
        -webkit-flex: 1 1;
                flex: 1 1; } }
    @media screen and (max-width: 639px) {
      .MainDashboard .next-report {
        -webkit-flex-direction: column;
                flex-direction: column;
        margin-left: 0;
        margin-top: 16px; } }
    .MainDashboard .next-report .report {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-flex-direction: column;
              flex-direction: column;
      padding: 16px 8px;
      -webkit-flex: 1 1;
              flex: 1 1; }
      .MainDashboard .next-report .report .reporting-period {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center; }
        .MainDashboard .next-report .report .reporting-period h2 {
          margin-left: 16px; }
    .MainDashboard .next-report .file-report-actions {
      margin-top: 32px; }
  .MainDashboard .finance-content {
    -webkit-order: 2;
            order: 2;
    background-color: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: stretch;
            align-items: stretch;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex: 2 1;
            flex: 2 1;
    font-size: .9rem;
    color: #323130;
    position: relative; }
    .MainDashboard .finance-content .refresh-info {
      position: absolute;
      top: 0px;
      right: 24px;
      font-size: 12px;
      padding-top: 16px; }
      .MainDashboard .finance-content .refresh-info .refresh-button {
        display: inline-block;
        font-size: 12px; }
    @media screen and (max-width: 840px) {
      .MainDashboard .finance-content {
        -webkit-order: 3;
                order: 3;
        -webkit-flex: 12 0 100%;
                flex: 12 0 100%;
        margin-top: 16px; } }
    .MainDashboard .finance-content .finances {
      padding: 16px 32px;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      display: inline-block;
      width: 80%;
      margin: 0 auto;
      font-size: 0.9rem; }
    .MainDashboard .finance-content .secondary-actions {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: space-around;
              justify-content: space-around;
      margin-top: 16px;
      padding-bottom: 8px; }
      @media screen and (max-width: 840px) and (min-width: 640px) {
        .MainDashboard .finance-content .secondary-actions {
          -webkit-flex-direction: column;
                  flex-direction: column;
          -webkit-justify-content: flex-start;
                  justify-content: flex-start;
          -webkit-align-items: flex-start;
                  align-items: flex-start; } }
  .MainDashboard .finance-content-old {
    -webkit-order: 2;
            order: 2;
    background-color: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: stretch;
            align-items: stretch;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex: 2 1;
            flex: 2 1;
    position: relative; }
    .MainDashboard .finance-content-old .refresh-button {
      position: absolute;
      top: 0px;
      right: 24px;
      font-size: 12px; }
    @media screen and (max-width: 840px) {
      .MainDashboard .finance-content-old {
        -webkit-order: 3;
                order: 3;
        -webkit-flex: 12 0 100%;
                flex: 12 0 100%;
        margin-top: 16px; } }
    .MainDashboard .finance-content-old .finances {
      padding: 16px 32px;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      display: inline-block;
      width: 65%;
      margin: 0 auto; }
    .MainDashboard .finance-content-old .money-raised-chart {
      padding: 16px 32px;
      width: auto;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center; }
    .MainDashboard .finance-content-old .no-money-raised {
      display: -webkit-flex;
      display: flex; }
      .MainDashboard .finance-content-old .no-money-raised p {
        color: #000000aa;
        font-size: 0.8rem;
        width: 150px;
        margin-left: 16px; }
    .MainDashboard .finance-content-old .secondary-actions {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: space-around;
              justify-content: space-around;
      margin-top: 16px;
      padding-bottom: 8px; }
      @media screen and (max-width: 840px) and (min-width: 640px) {
        .MainDashboard .finance-content-old .secondary-actions {
          -webkit-flex-direction: column;
                  flex-direction: column;
          -webkit-justify-content: flex-start;
                  justify-content: flex-start;
          -webkit-align-items: flex-start;
                  align-items: flex-start; } }
  .MainDashboard .dashboard-actions {
    -webkit-order: 4;
            order: 4;
    width: 100%;
    background-color: #fff;
    -webkit-flex: 12 0 100%;
            flex: 12 0 100%;
    margin-top: 16px; }
    .MainDashboard .dashboard-actions .site-actions {
      padding: 32px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
      @media screen and (max-width: 1023px) {
        .MainDashboard .dashboard-actions .site-actions {
          -webkit-flex-direction: column;
                  flex-direction: column; } }
      .MainDashboard .dashboard-actions .site-actions .sub-col {
        -webkit-flex: 1 1;
                flex: 1 1;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: flex-start;
                align-items: flex-start;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        border-right: 1px solid #ddd;
        padding-left: 16px;
        padding-right: 8px; }
        .MainDashboard .dashboard-actions .site-actions .sub-col:last-of-type {
          border-right: none; }
        .MainDashboard .dashboard-actions .site-actions .sub-col:first-of-type {
          padding-left: 0; }
        .MainDashboard .dashboard-actions .site-actions .sub-col .action-btn,
        .MainDashboard .dashboard-actions .site-actions .sub-col div .action-btn {
          width: 95%;
          box-sizing: border-box;
          margin: 4px;
          padding: 16px 8px;
          -webkit-flex: 1 1;
                  flex: 1 1; }
        @media screen and (max-width: 1023px) {
          .MainDashboard .dashboard-actions .site-actions .sub-col {
            padding: 0;
            border: none;
            border-bottom: 1px solid #999;
            -webkit-align-items: center;
                    align-items: center;
            padding: 8px 0; } }

.inactive-account {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 65px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99; }
  .inactive-account h3 {
    color: #fff;
    font-size: 36px; }
  .inactive-account p {
    color: #fff;
    font-size: 24px; }
    .inactive-account p button {
      color: #fff;
      text-decoration: underline;
      font-weight: bold; }
      .inactive-account p button:hover {
        color: gray; }

.ManageUsers {
  background-color: #fff;
  width: 85%;
  margin: 50px auto;
  padding: 24px; }
  .ManageUsers .manage-user-actions .manage-user-btn {
    margin-right: 16px; }

.ReconciliationReport {
  background-color: white;
  margin: 30px auto;
  padding: 32px 48px 128px;
  width: 75%;
  box-sizing: border-box; }
  .ReconciliationReport .print-link {
    cursor: pointer;
    text-align: right;
    display: block;
    color: blue;
    text-decoration: underline; }
  @media print {
    .ReconciliationReport {
      box-shadow: none !important;
      padding: 8px;
      margin: 0cm !important; }
      .ReconciliationReport .print-link {
        display: none; } }
  @media print and (orientation: landscape) {
    .ReconciliationReport {
      width: 10.75in; } }
  @media print and (orientation: portrait) {
    .ReconciliationReport {
      width: 8.25in; } }
  .ReconciliationReport .heading {
    width: 100%;
    text-align: center; }
    .ReconciliationReport .heading p {
      margin: 0;
      padding: 0;
      line-height: 24px;
      font-weight: bold; }
  .ReconciliationReport .content {
    height: auto;
    overflow: visible;
    padding: 0 24px;
    box-shadow: none; }
    @media print {
      .ReconciliationReport .content {
        padding: 0; } }
    .ReconciliationReport .content .page-break {
      display: none; }
    .ReconciliationReport .content h4 {
      width: 100%;
      text-decoration: underline; }
    .ReconciliationReport .content table {
      width: 100%;
      border-collapse: collapse;
      height: auto;
      max-height: 100vh;
      page-break-after: avoid;
      page-break-before: avoid; }
      .ReconciliationReport .content table thead {
        page-break-after: avoid;
        page-break-before: avoid; }
        .ReconciliationReport .content table thead tr {
          page-break-after: avoid;
          page-break-before: avoid; }
          .ReconciliationReport .content table thead tr th {
            border-bottom: 1px solid #919191;
            text-align: left;
            padding: 4px 4px 4px 8px; }
            .ReconciliationReport .content table thead tr th.amount {
              text-align: right; }
      .ReconciliationReport .content table tbody tr td {
        padding: 4px 4px 4px 8px;
        text-align: left; }
        .ReconciliationReport .content table tbody tr td.amount {
          text-align: right; }
      .ReconciliationReport .content table tfoot {
        border-top: 1px solid #919191; }
        .ReconciliationReport .content table tfoot tr {
          height: 50px; }
          .ReconciliationReport .content table tfoot tr td {
            padding: 4px 4px 4px 8px; }
            .ReconciliationReport .content table tfoot tr td.amount {
              text-align: right; }
              .ReconciliationReport .content table tfoot tr td.amount span {
                margin-right: 24px; }

@media print {
  html,
  body {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: auto; } }

.Reconciliations-wrapper .Reconciliations {
  width: 85%;
  margin: 12px auto;
  padding: 24px 24px 128px;
  background-color: #fff; }
  .Reconciliations-wrapper .Reconciliations .rec-action-btn {
    margin-right: 16px; }
  .Reconciliations-wrapper .Reconciliations .reconciliations-list .fr-reconciliation-row-item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    box-sizing: border-box;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #cacaca;
    padding: 8px 16px; }
    .Reconciliations-wrapper .Reconciliations .reconciliations-list .fr-reconciliation-row-item .rec-details {
      margin: 0;
      padding: 8px 0 0 16px;
      box-sizing: border-box;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      width: 100%;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; }
      .Reconciliations-wrapper .Reconciliations .reconciliations-list .fr-reconciliation-row-item .rec-details h3 {
        color: #919191;
        padding: 0;
        margin: 0 0 16px 0; }
      .Reconciliations-wrapper .Reconciliations .reconciliations-list .fr-reconciliation-row-item .rec-details .balance {
        font-size: 1.5rem;
        margin: 0;
        padding: 0; }
      .Reconciliations-wrapper .Reconciliations .reconciliations-list .fr-reconciliation-row-item .rec-details .rec-info {
        display: -webkit-flex;
        display: flex;
        box-sizing: border-box;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap; }
        .Reconciliations-wrapper .Reconciliations .reconciliations-list .fr-reconciliation-row-item .rec-details .rec-info .starting-info {
          -webkit-flex: 1 1;
                  flex: 1 1; }
        .Reconciliations-wrapper .Reconciliations .reconciliations-list .fr-reconciliation-row-item .rec-details .rec-info .ending-info {
          -webkit-flex: 1 1;
                  flex: 1 1; }
    .Reconciliations-wrapper .Reconciliations .reconciliations-list .fr-reconciliation-row-item .rec-row-actions {
      -webkit-flex: 1 1;
              flex: 1 1;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: flex-end;
              align-items: flex-end;
      width: 100%; }
  .Reconciliations-wrapper .Reconciliations .ms-Grid-row {
    margin-top: 32px; }
  .Reconciliations-wrapper .Reconciliations .no-reconciliations {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
    .Reconciliations-wrapper .Reconciliations .no-reconciliations h2 {
      margin-left: 32px; }

@media screen and (max-width: 880px) {
  .Reconciliations-wrapper .Reconciliations {
    margin-top: 0;
    width: 100%; } }

.VerifyFECReport {
  background-color: #fff;
  margin: 10px auto 30px;
  width: 65%;
  padding: 24px; }
  .VerifyFECReport .verify-fec-report-actions {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center; }
  .VerifyFECReport pre.override-base-font-for-pre {
    font-family: monospace !important;
    font-size: 0.8rem; }
  .VerifyFECReport .verify-button {
    margin-right: 5px; }

.VerifyReport {
  margin: 10px auto 64px;
  width: 83%; }
  .VerifyReport .error {
    color: #a00000; }
  .VerifyReport .instructions {
    background-color: #fff;
    padding: 24px;
    margin-bottom: 16px; }
    .VerifyReport .instructions p {
      width: 95%;
      margin: 8px auto 24px;
      line-height: 27px; }
  .VerifyReport .verify-report-content {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: stretch;
            align-items: stretch;
    box-sizing: border-box;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    .VerifyReport .verify-report-content .report {
      -webkit-flex: 1 1;
              flex: 1 1;
      background-color: #fff;
      padding: 16px;
      margin-right: 16px;
      min-height: 300px;
      box-sizing: border-box;
      max-width: calc(60% - 32px); }
      .VerifyReport .verify-report-content .report pre {
        background-color: #e3ecf7;
        border: 1px solid #cdd7e2;
        color: #607188;
        padding: 10px;
        overflow: auto;
        max-height: 150px; }
      .VerifyReport .verify-report-content .report .verify-report-actions {
        margin-top: 16px; }
    .VerifyReport .verify-report-content .response {
      -webkit-flex: 2 1;
              flex: 2 1;
      background-color: #fff;
      padding: 16px;
      box-sizing: border-box; }
      .VerifyReport .verify-report-content .response .error-field-wrapper {
        padding: 4px 8px;
        background-color: #f3f3f3;
        margin-bottom: 8px;
        border: 1px solid rgba(55, 55, 55, 0.2); }
    @media screen and (max-width: 860px) {
      .VerifyReport .verify-report-content {
        -webkit-flex-direction: column;
                flex-direction: column; }
        .VerifyReport .verify-report-content .report {
          -webkit-flex: 1 1;
                  flex: 1 1;
          margin-right: 0;
          margin-bottom: 16px;
          max-width: 100%; }
        .VerifyReport .verify-report-content .response {
          -webkit-flex: 1 1;
                  flex: 1 1;
          max-width: 100%; } }

.ObservableContributionsList .search-line{display:-webkit-flex;display:flex}.ObservableContributionsList .search-line .date-label{margin-top:4px;margin-right:8px;margin-left:16px}.ObservableContributionsList .no-employee-or-occupation{margin-left:32px;margin-top:6px}.ObservableContributionsList .contribution-header-icon{font-size:16px}

.ViewAllContributions {
  margin: 15px auto auto;
  padding: 24px;
  background-color: #fff;
  width: 85%; }
  .ViewAllContributions .contributions-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 16px; }
  .ViewAllContributions .back-to-top {
    position: absolute;
    left: 13px;
    bottom: 80px;
    background-color: white;
    overflow: hidden;
    border-radius: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 34px;
    height: 34px;
    box-shadow: 4px 4px 4px -1px rgba(0, 0, 0, 0.22); }

.compact-loan-details {
  padding: 16px; }

.expanded-loan-details {
  padding: 16px; }

.ObservableExpendituresList .search-line{display:-webkit-flex;display:flex}.ObservableExpendituresList .search-line .date-label{margin-top:4px;margin-right:8px;margin-left:16px}.ObservableExpendituresList .no-employee-or-occupation{margin-left:32px;margin-top:6px}.ObservableExpendituresList .expenditure-header-icon{font-size:16px}

.ViewAllExpenditures {
  margin: 45px auto 256px;
  padding: 24px;
  background-color: #fff;
  width: 85%; }
  .ViewAllExpenditures .expenditures-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 16px; }
  .ViewAllExpenditures .back-to-top {
    position: absolute;
    left: 13px;
    bottom: 80px;
    background-color: white;
    overflow: hidden;
    border-radius: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 34px;
    height: 34px;
    box-shadow: 4px 4px 4px -1px rgba(0, 0, 0, 0.22); }

.bulk-recipient-row:hover {
  background-color: #DDDDDD; }

.compact-loan-details {
  padding: 16px; }

.expanded-loan-details {
  padding: 16px; }

.bulk-recipient-section {
  maxHeight: 300px;
  overflow: auto; }

.IowaExports {
  margin: 45px auto auto;
  padding: 24px;
  background-color: #fff;
  width: 85%;
  min-height: 300px; }
  @media screen and (max-width: 1024px) {
    .IowaExports {
      width: 100%;
      margin-top: 0px;
      padding: 8px; } }

.ViewAllLoans {
  margin: 45px auto auto;
  padding: 24px;
  background-color: #fff;
  width: 85%; }
  @media screen and (max-width: 1024px) {
    .ViewAllLoans {
      width: 100%;
      margin-top: 0px;
      padding: 8px; } }
  .ViewAllLoans .loans-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center; }

.compact-loan-details {
  padding: 16px; }

.expanded-loan-details {
  padding: 16px; }

.PaginatedList .paginated-list-pagination-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 1rem; }

.PaginatedList .paginated-list-dropdown-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.PaginatedList .paginated-list-dropdown-label {
  margin-right: 1rem; }

.PaginatedList .pagination {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  list-style: none;
  padding-left: 1.5rem; }
  .PaginatedList .pagination a {
    padding: 10px;
    font-size: 15px;
    cursor: pointer; }
    .PaginatedList .pagination a:hover {
      color: var(--info-color); }
  .PaginatedList .pagination .pagination__link {
    font-weight: 600; }
  .PaginatedList .pagination .pagination__link--active a {
    color: var(--primary-color);
    font-weight: 600; }
  .PaginatedList .pagination .pagination__link--disabled a {
    color: var(--disabled-color);
    cursor: not-allowed; }

.Badge {
  color: white;
  font-weight: 600;
  padding: 0.4rem 1.25rem;
  border-radius: 8px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 0.75rem; }

.ImportList {
  margin: 15px auto auto;
  padding: 24px;
  background-color: #fff;
  width: 85%; }
  .ImportList .import-list-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center; }
  .ImportList .import-list-actions-cell {
    display: -webkit-flex;
    display: flex;
    grid-gap: 1.5rem;
    gap: 1.5rem; }

.Steps {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  margin-bottom: 1rem; }
  .Steps .step-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }
  .Steps .step-progress-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%; }
  .Steps .step-divider {
    -webkit-flex: 1 1;
            flex: 1 1;
    height: 1.5px; }
  .Steps .step-index-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 2rem;
    height: 2rem;
    border-width: 2px;
    border-style: solid;
    border-radius: 3px;
    margin: 0 0.25rem; }
  .Steps .step-index-container-value {
    font-size: 1rem;
    letter-spacing: 1.5px;
    font-weight: 700; }
  .Steps .step-info {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 0.25rem; }
  .Steps .step-title {
    font-size: 0.625rem;
    line-height: 1rem;
    font-weight: 700;
    letter-spacing: 1.5px;
    text-transform: uppercase; }
  .Steps .step-label {
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.25px;
    font-weight: 600; }

.CustomizedImport {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: hidden; }
  .CustomizedImport .customized-import-table {
    -webkit-flex: 1 1;
            flex: 1 1;
    overflow: auto; }
  .CustomizedImport .customized-import-footer {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center; }
  .CustomizedImport .customized-import-bttn-container {
    display: -webkit-flex;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    -webkit-align-items: center;
            align-items: center; }

.CustomizedImport__DropdownRequiredOption {
  color: var(--error-color); }

.ColumnMappingStep {
  width: 100%; }

.QuestionsStep {
  padding-bottom: 2rem; }
  .QuestionsStep .questions-step-questions-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    min-width: 400px; }

.UploadFile {
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  outline: none;
  cursor: pointer;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100%; }
  .UploadFile .upload-file-caption-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    width: 80%; }
  .UploadFile .upload-file-caption {
    font-size: 1.125rem;
    text-align: center; }
  .UploadFile .upload-file-icon {
    font-size: 5rem;
    margin-top: 2rem; }

.UploadStep {
  width: 100%;
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding-bottom: 2rem;
  width: 100%; }
  .UploadStep .upload-back-bttn {
    width: 100%; }
  .UploadStep .upload-progress-indicator {
    width: 100%;
    font-weight: 600; }

.ImportWizard {
  margin: 15px auto auto;
  padding: 1.5rem;
  background-color: #fff;
  width: 85%;
  min-height: 600px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }
  .ImportWizard .import-wizard-spinner {
    margin: auto 0; }
  .ImportWizard .import-wizard-content {
    margin-top: 2rem;
    width: 90%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex: 1 1;
            flex: 1 1; }
  .ImportWizard .import-wizard-step-bttns {
    display: -webkit-flex;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 2rem;
    -webkit-align-items: center;
            align-items: center; }

.EditImportRowDialogBody{max-height:60vh;overflow:auto}.EditImportRowDialogBody .edit-import-row-errors{padding-left:1rem;margin:0}.EditImportRowDialogBody .edit-import-row-errors li:not(:first-of-type){margin-top:0.5rem}.EditImportRowDialogBody .edit-import-row-error-message{color:var(--error-color)}.EditImportRowDialogBody .edit-import-row-error-message-fixed{text-decoration:line-through}.EditImportRowDialogBody .edit-import-row-success-message{color:var(--success-color)}.EditImportRowDialogBody .edit-import-row-input-container{margin-top:1rem}.EditImportRowDialogBody .edit-import-row-input{margin-top:0.75rem}.EditImportRowDialogBody .edit-import-row-input-success-message{color:var(--success-color);padding-top:5px}.EditImportRowDialogBody__Footer{display:-webkit-flex;display:flex;-webkit-justify-content:flex-end;justify-content:flex-end;grid-gap:1rem;gap:1rem;margin-top:1rem}

.ImportErrors {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .ImportErrors .import-errors-table {
    -webkit-flex: 1 1;
            flex: 1 1;
    overflow: auto; }
  .ImportErrors .import-errors-content {
    margin: 15px auto auto;
    padding: 24px;
    background-color: #fff;
    width: 85%;
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    overflow: hidden; }
  .ImportErrors .import-errors-error-cell {
    white-space: break-spaces;
    color: var(--error-color); }
  .ImportErrors .import-errors-finalize-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center; }
  .ImportErrors .import-errors-finalize-bttn-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    grid-gap: 1rem;
    gap: 1rem; }

.ApiIntegration {
  margin: 15px auto auto;
  padding: 24px;
  background-color: #fff;
  width: 85%; }
  .ApiIntegration .filed-reports {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin: 8px;
    box-sizing: border-box;
    padding: 8px 16px 24px;
    background-color: #fff; }
  .ApiIntegration .report-type-original {
    background-color: rgba(0, 120, 212, 0.7);
    border-radius: 8px;
    color: white;
    padding: 4px 6px; }
    .ApiIntegration .report-type-original.final {
      background-color: rgba(168, 0, 0, 0.7); }
  .ApiIntegration .report-type-amended {
    background-color: var(--warning-color);
    border-radius: 8px;
    color: white;
    padding: 4px 6px; }
  .ApiIntegration .report-type-TBD, .ApiIntegration .report-type-48HourNotice {
    background-color: #107c1088;
    border-radius: 8px;
    color: white;
    padding: 4px 6px; }

.IntegrationSettingForm {
  background-color: #fff;
  margin: 24px auto 24px;
  position: relative; }
  @media screen and (max-width: 1024px) {
    .IntegrationSettingForm {
      width: 100%;
      margin-top: 0;
      padding: 8px; } }
  @media screen and (min-width: 1025px) and (max-width: 1920px) {
    .IntegrationSettingForm {
      width: 95%; } }
  .IntegrationSettingForm .actions {
    margin: 0 auto;
    text-align: right;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    background-color: #fff; }
    .IntegrationSettingForm .actions .integration-setting-btn {
      margin-left: 16px; }

@-webkit-keyframes slideinfromtop {
  from {
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: translate3d(0, -100px, 0);
            transform: translate3d(0, -100px, 0); }
  to {
    -webkit-transform: translate3d(0, 4px, 0);
            transform: translate3d(0, 4px, 0); } }

@keyframes slideinfromtop {
  from {
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: translate3d(0, -100px, 0);
            transform: translate3d(0, -100px, 0); }
  to {
    -webkit-transform: translate3d(0, 4px, 0);
            transform: translate3d(0, 4px, 0); } }

.Alert {
  position: fixed;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: auto;
  top: 4px;
  left: 4px;
  right: 4px;
  z-index: 99;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  color: #fff;
  padding: 8px 24px;
  box-sizing: border-box;
  -webkit-animation: slideinfromtop 500ms var(--animation-curve) 0ms;
          animation: slideinfromtop 500ms var(--animation-curve) 0ms; }
  .Alert.INFO {
    background-color: var(--info-color); }
  .Alert.SUCCESS {
    background-color: var(--success-color); }
  .Alert.ERROR {
    background-color: var(--error-color); }
  .Alert.WARNING {
    background-color: var(--warning-color); }
  .Alert p {
    margin-left: 16px;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    margin-right: 16px; }

.Dashboard {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch;
  width: 100vw; }
  .Dashboard .header {
    -webkit-flex: 65px 1;
            flex: 65px 1;
    background-color: #fff;
    box-sizing: border-box;
    padding: 4px 24px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    z-index: 25; }
    @media screen and (max-width: 479px) {
      .Dashboard .header {
        padding: 4px 8px; } }
    @media print {
      .Dashboard .header {
        display: none; } }
    .Dashboard .header .logo {
      width: 95px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
    .Dashboard .header .user-menu {
      -webkit-flex: 2 1;
              flex: 2 1;
      text-align: right;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end; }
      .Dashboard .header .user-menu .search {
        width: 280px;
        margin-right: 16px; }
    .Dashboard .header .admin-campaign-select {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
  .Dashboard .selected-campaign {
    padding: 4px 8px 4px 32px;
    background-color: #fafafa;
    border-top: 1px solid #919191aa;
    z-index: 25;
    margin-bottom: 0; }
    @media print {
      .Dashboard .selected-campaign {
        display: none; } }
  .Dashboard .content {
    margin: 0;
    padding: 0;
    height: calc(100vh - 125px);
    overflow: auto; }
    @media print {
      .Dashboard .content {
        height: auto; } }
    .Dashboard .content .choose-a-campaign {
      position: fixed;
      top: 60px;
      left: 0;
      right: 0;
      bottom: 60px;
      padding: 35px 65px 35px 135px;
      background-color: rgba(255, 255, 255, 0.9);
      z-index: 99;
      display: -webkit-flex;
      display: flex; }

body {
  background: #999 !important; }

.DepositTicket {
  background-color: #fff;
  margin: 30px auto;
  width: 85%;
  padding: 30px 16px;
  height: auto;
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }
  @media print {
    .DepositTicket {
      box-shadow: none;
      width: 100%;
      margin-top: 16px; } }
  .DepositTicket .wrapper {
    display: -webkit-flex;
    display: flex; }
    .DepositTicket .wrapper .content {
      display: -webkit-flex;
      display: flex;
      -webkit-flex: 1 1;
              flex: 1 1; }
      .DepositTicket .wrapper .content .header {
        -webkit-writing-mode: tb-rl;
            -ms-writing-mode: tb-rl;
                writing-mode: tb-rl;
        -webkit-transform: rotate(-180deg);
                transform: rotate(-180deg);
        margin: 0;
        box-sizing: border-box;
        font-size: 0.85rem;
        height: 300px;
        width: 64px;
        text-align: center; }
      .DepositTicket .wrapper .content .campaign-details {
        padding: 0 16px 16px;
        margin: 0;
        box-sizing: border-box;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column; }
        .DepositTicket .wrapper .content .campaign-details h3 {
          font-weight: bold;
          margin: 0; }
        .DepositTicket .wrapper .content .campaign-details address p {
          margin: 0 2px;
          font-style: normal; }
        .DepositTicket .wrapper .content .campaign-details .date {
          margin-top: 32px; }
          .DepositTicket .wrapper .content .campaign-details .date span {
            font-weight: 600;
            margin-right: 8px; }
        .DepositTicket .wrapper .content .campaign-details .bank-info {
          margin-top: 32px;
          visibility: hidden; }
    .DepositTicket .wrapper .deposits-list {
      -webkit-flex: 1 1;
              flex: 1 1;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end; }
      .DepositTicket .wrapper .deposits-list table {
        border: 1px solid #919191;
        width: 80%;
        border-collapse: collapse; }
        .DepositTicket .wrapper .deposits-list table tbody tr td {
          border: 1px solid #919191;
          padding: 6px; }
          .DepositTicket .wrapper .deposits-list table tbody tr td.label-cell {
            background-color: #9a9a9a;
            color: white; }
            @media print {
              .DepositTicket .wrapper .deposits-list table tbody tr td.label-cell {
                color: #919191;
                background-color: white; } }
          .DepositTicket .wrapper .deposits-list table tbody tr td p {
            margin: 2px; }
  .DepositTicket .deposit-ticket-footer {
    width: 100%;
    text-align: center;
    font-size: 0.8rem; }

.DepositTicket-actions {
  width: 85%;
  margin: 20px auto 80px;
  text-align: center;
  background-color: white;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }
  @media print {
    .DepositTicket-actions {
      display: none;
      box-shadow: none; } }

.ForgotPassword {
  background-color: #fff;
  margin: 60px auto 0;
  padding: 24px;
  max-width: 600px;
  box-sizing: border-box; }
  @media screen and (max-width: 639px) {
    .ForgotPassword {
      width: 98vw;
      position: absolute;
      left: 1vw;
      top: 1vh;
      height: 94vh;
      margin: 0 auto;
      max-width: none; } }
  .ForgotPassword .logo {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 32px; }
  .ForgotPassword .actions {
    margin-top: 32px; }
  .ForgotPassword .cancel-btn {
    margin-right: 16px; }
  .ForgotPassword .ms-TextField-wrapper .ms-TextField-fieldGroup {
    background: #fff; }

.ResetPassword {
  background-color: #fff;
  margin: 60px auto 0;
  padding: 24px;
  max-width: 600px;
  box-sizing: border-box; }
  .ResetPassword .ms-Grid-col {
    margin-bottom: 8px; }
  .ResetPassword .logo {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 32px; }
  .ResetPassword .actions {
    margin-top: 32px; }
  .ResetPassword .cancel-btn {
    margin-right: 16px; }

.Signup-wrapper {
  overflow: auto;
  height: 95vh; }
  .Signup-wrapper .cancel-btn {
    margin-right: 8px; }
  .Signup-wrapper .Signup {
    background-color: #fff;
    margin: 60px auto 128px;
    padding: 48px;
    max-width: 840px;
    box-sizing: border-box; }
    @media screen and (max-width: 639px) {
      .Signup-wrapper .Signup {
        width: 98vw;
        position: absolute;
        left: 1vw;
        top: 0;
        max-width: none;
        height: 94vh;
        margin: 0;
        padding-bottom: 128px;
        overflow: auto; } }
    .Signup-wrapper .Signup .logo {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      margin-bottom: 32px; }
    .Signup-wrapper .Signup .ms-Grid-row {
      margin-bottom: 8px; }

.OfficeDetails .ms-Grid-col {
  margin-bottom: 8px; }

.PaymentStep {
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media screen and (max-width: 500px) {
    .PaymentStep {
      -webkit-flex-direction: column;
              flex-direction: column; } }
  .PaymentStep .actions {
    margin-top: 32px; }
    .PaymentStep .actions .back-btn {
      margin-right: 8px; }

.Summary .lead-column {
  display: -webkit-flex;
  display: flex;
  margin-top: 64px;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  @media screen and (max-width: 639px) {
    .Summary .lead-column {
      margin-top: 0; } }

.Summary .summary-label {
  font-weight: bold;
  font-size: 1.3rem; }
  .Summary .summary-label + p {
    padding: 0;
    font-size: 1.1rem;
    margin-bottom: 24px;
    margin-top: 0; }

.CreateCampaign {
  background-color: #fff;
  margin: 30px auto 0;
  padding: 24px;
  max-width: 960px;
  box-sizing: border-box; }
  @media screen and (max-width: 500px) {
    .CreateCampaign {
      width: 100vw;
      position: absolute;
      left: 0;
      top: 0;
      max-width: none; }
      .CreateCampaign .content {
        position: relative;
        padding-bottom: 256px; } }

.logout-btn {
  position: absolute;
  left: 10px;
  top: 10px; }

.VerifyInvite-wrapper .VerifyInvite {
  background-color: #fff;
  padding: 48px 64px;
  margin: 60px auto 128px;
  box-sizing: border-box;
  max-width: 840px; }
  .VerifyInvite-wrapper .VerifyInvite .logo {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 32px; }
  .VerifyInvite-wrapper .VerifyInvite .ms-Grid-row {
    margin-top: 16px; }
  .VerifyInvite-wrapper .VerifyInvite .verify-invite-actions {
    margin-top: 32px; }

.App {
  height: auto;
  width: auto;
  box-sizing: border-box;
  margin: 0;
  padding: 0; }
  .App h3 {
    font-weight: 100;
    color: #2c3e50;
    text-transform: uppercase; }
    .App h3 strong {
      font-weight: bold; }
  .App .page-content {
    position: absolute;
    top: 60px;
    bottom: 50px;
    left: 0;
    right: 0;
    overflow: auto;
    padding-bottom: 40px; }
  .App [id*='amount'].ms-DetailsHeader-cellTitle,
  .App [id*='credit'].ms-DetailsHeader-cellTitle,
  .App [id*='debit'].ms-DetailsHeader-cellTitle,
  .App [id*='balance'].ms-DetailsHeader-cellTitle {
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
  .App .footer {
    min-width: 375px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background-color: #2b2f35;
    color: #fff;
    padding: 4px 24px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .App .footer .info {
      font-size: 0.8em; }
      .App .footer .info div {
        text-align: left; }
    @media print {
      .App .footer {
        display: none; } }
  .App .error-message {
    color: var(--error-color);
    font-size: 0.85em; }

:root {
  --info-color: #71afe5;
  --primary-color: #244b8d;
  --success-color: #107c10;
  --error-color: #a80000;
  --warning-color: #ff8c00;
  --disabled-color: #c6c5ca;
  --error-icon-color: #e81123;
  --animation-curve: cubic-bezier(0.87, -0.41, 0.19, 1.44); }

html
*:not([class*='fa-']):not([class*='icon-']):not([data-icon-name]):not([class*='override-base-font']) {
  font-family: 'Open Sans', sans-serif !important; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Segoe', sans-serif;
  background-color: #999;
  background-image: linear-gradient(to bottom, #999 0%, #8a8a8a 100%);
  overflow-x: hidden;
  height: 100vh; }

.ms-Overlay {
  background-color: rgba(0, 0, 0, 0.7); }

.depth-1 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-sizing: border-box; }

.go-back {
  background: linear-gradient(to right, #636363 calc(100%), #63636399 20px);
  position: relative;
  top: 2px;
  left: 0;
  right: 0;
  box-shadow: 0 1px rgba(0, 0, 0, 0.2);
  z-index: 28; }
  @media print {
    .go-back {
      display: none; } }
  @media screen and (max-width: 400px) {
    .go-back {
      background-color: #636363; } }

.error-message {
  color: #a80000;
  color: var(--error-color);
  font-size: 0.85em; }

[data-icon-name='Down'] {
  font-family: 'Font Awesome 5 Free' !important;
  font-style: normal;
  position: relative; }
  [data-icon-name='Down']::after {
    content: '\f063';
    color: #333;
    font-weight: 900; }

[data-icon-name='Up'] {
  font-family: 'Font Awesome 5 Free' !important;
  font-style: normal;
  position: relative; }
  [data-icon-name='Up']::after {
    content: '\f062';
    color: #333;
    font-weight: 900; }

[data-icon-name='StatusCircleCheckmark'] {
  font-family: 'Font Awesome 5 Free' !important;
  font-style: normal;
  position: relative; }
  [data-icon-name='StatusCircleCheckmark']::after {
    content: '\f058';
    font-weight: 900;
    font-size: 13px;
    margin-right: 1px !important; }

[data-icon-name='CheckMark'] {
  font-family: 'Font Awesome 5 Free' !important;
  font-style: normal;
  position: relative; }
  [data-icon-name='CheckMark']::after {
    content: '\f00c';
    font-weight: 900;
    font-size: 13px;
    margin-right: 1px !important; }

[data-icon-name='Cancel'] {
  font-family: 'Font Awesome 5 Free' !important;
  font-style: normal;
  position: relative; }
  [data-icon-name='Cancel']::after {
    content: '\f00d';
    font-weight: 900;
    font-size: 13px;
    margin-right: 1px !important; }

[data-icon-name='ErrorBadge'] {
  font-family: 'Font Awesome 5 Free' !important;
  font-style: normal;
  position: relative; }
  [data-icon-name='ErrorBadge']::after {
    content: '\f057';
    font-weight: 900;
    font-size: 13px;
    margin-right: 1px !important; }

[data-icon-name='Completed'] {
  font-family: 'Font Awesome 5 Free' !important;
  font-style: normal;
  position: relative; }
  [data-icon-name='Completed']::after {
    content: '\f058';
    font-weight: 900;
    font-size: 13px;
    margin-right: 1px !important; }

[data-icon-name='Calendar'] {
  font-family: 'Font Awesome 5 Free' !important;
  font-style: normal;
  position: relative; }
  [data-icon-name='Calendar']::after {
    content: '\f073';
    font-weight: 900;
    font-size: 19px;
    margin-right: 1px !important; }

