.Lists {
  width: 100%;
  height: auto;
  min-height: 800px;
  margin-top: 40px;
  display: flex;
  align-items: stretch; }
  .Lists .options-panel {
    width: 300px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #dadada;
    position: absolute;
    height: calc(100vh - 164px);
    overflow-x: hidden; }
    .Lists .options-panel .column {
      box-sizing: border-box;
      padding: 6px 12px 6px 6px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .Lists .options-panel .column:hover {
        background-color: rgba(170, 170, 170, 0.3); }
  .Lists .list {
    left: 300px;
    right: 0;
    box-sizing: border-box;
    background-color: #fff;
    height: calc(100vh - 164px);
    position: absolute; }
    .Lists .list.loading-csv {
      padding-top: 165px; }
