.LoanForm {
  width: 85%;
  background-color: #fff;
  margin: 30px auto 256px;
  padding: 24px; }
  @media screen and (max-width: 1024px) {
    .LoanForm {
      width: 100%;
      margin-top: 0;
      padding: 8px; } }
  @media screen and (min-width: 1025px) and (max-width: 1920px) {
    .LoanForm {
      width: 95%; } }
  .LoanForm .ms-Grid-col {
    margin-bottom: 16px; }
  .LoanForm .payment-type-section {
    padding: 8px 24px;
    background-color: #f3f3f3; }
  .LoanForm .individual-federal-loan .is-secured {
    display: flex; }
  .LoanForm .business-federal-loan .restructured {
    background-color: #91919122;
    padding-bottom: 8px;
    padding-left: 24px; }
    .LoanForm .business-federal-loan .restructured:last-of-type {
      padding-bottom: 9px; }
  .LoanForm .business-federal-loan .location-of-account {
    background-color: #91919122;
    padding-bottom: 8px;
    padding-left: 24px; }
  .LoanForm .loan-actions-wrapper {
    position: fixed;
    background-color: #fff;
    bottom: -3px;
    z-index: 1;
    left: 0;
    right: 0;
    padding: 16px;
    box-shadow: 0 -1px 8px -1px rgba(0, 0, 0, 0.83); }
    .LoanForm .loan-actions-wrapper .actions {
      width: 85%;
      margin: 0 auto;
      text-align: right;
      flex-grow: 1; }
      .LoanForm .loan-actions-wrapper .actions .loan-btn {
        margin-left: 16px; }
  .LoanForm .add-responsible-party {
    margin-top: 32px; }
  .LoanForm .responsible-party {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #fff;
    color: #fff;
    background-color: rgba(0, 120, 212, 0.7);
    padding: 0 16px; }
