.LabelGroup {
  display: block;
  box-sizing: border-box; }
  .LabelGroup label {
    box-sizing: border-box; }
    .LabelGroup label .subText {
      box-sizing: border-box;
      margin-left: 12px; }
  .LabelGroup p {
    font-weight: 300;
    box-sizing: border-box;
    padding-left: 18px;
    margin-top: 8px; }
  .LabelGroup.non-numbered label {
    padding-left: 16px; }
