.columns-filter,
.filters-filter {
  margin-top: 32px; }

.filter-subset {
  border: 1px solid rgba(55, 55, 55, 0.3);
  padding: 8px;
  margin-bottom: 8px;
  background-color: #deecf9; }
  .filter-subset h4 {
    margin-top: 8px;
    padding: 0; }
