.MergeContactsPanel {
  width: 85%;
  margin: 0 auto; }
  .MergeContactsPanel .source-contacts {
    background-color: #f3f3f3;
    border: 1px solid rgba(0, 0, 0, 0.2); }

.MergeOverlay {
  user-select: none; }
  .MergeOverlay .merge-warning {
    margin-top: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .MergeOverlay .merge-warning p {
      color: #fff;
      font-size: 2.5rem; }
    .MergeOverlay .merge-warning .merge-warning-actions .cancel-btn {
      margin-right: 16px; }
