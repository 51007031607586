.Deposits-wrapper {
  height: calc(100vh - 125px); }
  .Deposits-wrapper .Deposits {
    background-color: #fff;
    width: 65%;
    height: auto;
    padding: 24px 24px 128px;
    box-sizing: border-box;
    margin: 20px auto 50px; }
    @media screen and (max-width: 959px) {
      .Deposits-wrapper .Deposits {
        width: 100%;
        margin-top: 0;
        padding-bottom: 128px; } }
    @media screen and (max-width: 639px) {
      .Deposits-wrapper .Deposits {
        width: 100%;
        padding: 8px 8px 0;
        overflow: auto; } }
    .Deposits-wrapper .Deposits .ms-Grid-row {
      margin-top: 32px; }
    .Deposits-wrapper .Deposits .no-deposits {
      display: flex;
      justify-content: center;
      align-items: center; }
      .Deposits-wrapper .Deposits .no-deposits h2 {
        margin-left: 32px; }
    .Deposits-wrapper .Deposits .deposit-action-btn {
      margin-right: 16px; }
    .Deposits-wrapper .Deposits .deposits-list .fr-deposit-row-item {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      box-sizing: border-box;
      border-top: 1px solid #eaeaea;
      border-bottom: 1px solid #cacaca;
      padding: 8px 16px;
      margin-bottom: 0; }
      .Deposits-wrapper .Deposits .deposits-list .fr-deposit-row-item .deposit-details {
        flex: 2;
        margin: 0;
        padding: 8px 0 0 16px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column; }
        .Deposits-wrapper .Deposits .deposits-list .fr-deposit-row-item .deposit-details h3 {
          color: #919191;
          padding: 0;
          margin: 0; }
        .Deposits-wrapper .Deposits .deposits-list .fr-deposit-row-item .deposit-details .amount {
          font-size: 1.8rem;
          margin: 0;
          padding: 0; }
      .Deposits-wrapper .Deposits .deposits-list .fr-deposit-row-item .deposit-row-actions {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%; }
        .Deposits-wrapper .Deposits .deposits-list .fr-deposit-row-item .deposit-row-actions > span {
          font-style: italic;
          color: #616161;
          margin-top: 48px; }
      @media screen and (max-width: 639px) {
        .Deposits-wrapper .Deposits .deposits-list .fr-deposit-row-item {
          flex-direction: column;
          padding: 4px 8px; }
          .Deposits-wrapper .Deposits .deposits-list .fr-deposit-row-item .deposit-details {
            flex: 1; }
          .Deposits-wrapper .Deposits .deposits-list .fr-deposit-row-item .deposit-row-actions {
            flex: 1;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: space-between; }
            .Deposits-wrapper .Deposits .deposits-list .fr-deposit-row-item .deposit-row-actions > span {
              position: relative;
              bottom: inherit;
              padding-left: 24px;
              margin-top: 0; } }

.deposit-details-panel .details-wrapper {
  position: relative;
  margin-bottom: 24px; }
  @media screen and (min-height: 900px) {
    .deposit-details-panel .details-wrapper {
      height: 400px;
      max-height: 400px; } }
