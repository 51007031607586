.VerifyInvite-wrapper .VerifyInvite {
  background-color: #fff;
  padding: 48px 64px;
  margin: 60px auto 128px;
  box-sizing: border-box;
  max-width: 840px; }
  .VerifyInvite-wrapper .VerifyInvite .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 32px; }
  .VerifyInvite-wrapper .VerifyInvite .ms-Grid-row {
    margin-top: 16px; }
  .VerifyInvite-wrapper .VerifyInvite .verify-invite-actions {
    margin-top: 32px; }
